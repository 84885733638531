import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, LinearProgress } from '@material-ui/core';

// Render with a delay, show spinner during delay. Use a trigger field to re-render.
const Delayed = ({ delay, triggerField, linear, children }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(false);
    setTimeout(() => {
      setIsShown(true);
    }, delay);
  }, [delay, triggerField]);

  return isShown ? (
    children
  ) : (
    <Box display="flex" justifyContent="center">
      {linear ? (
        <Box width="50px">
          <LinearProgress />
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

Delayed.propTypes = {
  delay: PropTypes.number,
  // use trigger field to re-render
  triggerField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linear: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Delayed.defaultProps = {
  delay: 500,
  linear: false,
  triggerField: undefined,
};

export default Delayed;
