import { Storage } from 'aws-amplify';

export const getFileFromURL = (name, url) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // check for google or  S3 bucket storage
      if (!url.includes('googleapis')) {
        Storage.get(name, { download: true })
          .then((res) => res.Body)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      } else {
        fetch(url)
          .then((response) => response.blob())
          .then((data) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      }
    });
  }, 2000);
};

const getFileSignedURL = (key, id, url) => {
  if (!url.includes('googleapis')) {
    Storage.get(key).then((value) => {
      const attachmentLink = document.getElementById(id);
      attachmentLink.href = value;
    });
  } else {
    const attachmentLink = document.getElementById(id);
    if (attachmentLink) {
      attachmentLink.href = url;
    }
  }
};

export const getPreSignedUrl = async (key) => {
  const url = await Storage.get(key, { level: 'public' });
  return url;
};

export const uploadFileToS3 = ({
  key,
  file,
  level = 'public',
  progressCallback = () => {},
}) => {
  // Set default value for progressCallback as an empty function
  return Storage.put(key, file, {
    level,
    contentType: file.type,
    progressCallback,
  });
};

export default getFileSignedURL;
