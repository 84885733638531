import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, TextField } from '@material-ui/core';
import { FdModal, FdAutocomplete } from '@fifthdomain/fe-shared';
import { getTagColor } from '../../shared/utils/tagUtils';

const AddRemoveTags = ({
  allTags,
  addRemoveTagModal,
  onConfirm,
  onDismiss,
  activeTask,
  setIndividualTagsAdded,
  setIndividualTagsDeleted,
}) => {
  const existingTags = activeTask?.assignedTags || [];

  return (
    <FdModal
      title="Add/Remove Tags"
      description={activeTask?.name}
      confirm="Save"
      dismiss="Cancel"
      showDismiss
      open={addRemoveTagModal}
      onConfirm={onConfirm}
      setOpen={addRemoveTagModal}
      onDismiss={onDismiss}
      maxWidth="md"
    >
      <Box height="200px" mt={2}>
        <FdAutocomplete
          label=""
          helperText="Type in existing tag names to apply them to selected challenges."
          options={allTags?.map((at) => ({
            name: at.name,
            id: at.id,
            color: at.color,
            displayColor: getTagColor(at.color),
          }))}
          multiple
          fullWidth
          selectAllLabel="Select all"
          optionLabel="name"
          optionValue="id"
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                style={{
                  backgroundColor: getTagColor(option.color),
                }}
              />
            ))
          }
          ListItemTextRenderer={({ option }) => {
            return (
              <Chip
                label={option.name}
                style={{
                  backgroundColor: option.displayColor,
                }}
              />
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Add an Existing Tag.."
            />
          )}
          defaultSelected={existingTags}
          onChange={(currentSelection) => {
            const existingTagIds = existingTags.map((et) => et.id);
            const added = currentSelection.filter(
              (et) => !existingTagIds.includes(et.id),
            );
            const deleted = existingTags.filter(
              (et) => !currentSelection.map((cs) => cs.id).includes(et.id),
            );
            setIndividualTagsDeleted(deleted);
            setIndividualTagsAdded(added);
          }}
        />
      </Box>
    </FdModal>
  );
};

AddRemoveTags.propTypes = {
  allTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addRemoveTagModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  activeTask: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  setIndividualTagsDeleted: PropTypes.func.isRequired,
  setIndividualTagsAdded: PropTypes.func.isRequired,
};

export default AddRemoveTags;
