import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import { FdTypography, FdTooltip, FdIconsV5 } from '@fifthdomain/fe-shared';

const FdTextView = ({
  label,
  value,
  hideOnEmpty,
  tooltip,
  noHeading,
  ...props
}) => {
  const { InfoOutlined } = FdIconsV5;
  const hide = hideOnEmpty && !value;

  return (
    !hide && (
      <Box my={1} {...props}>
        <Box className="flex items-center mb-1">
          {!noHeading && (
            <FdTypography variant="subtitle1">{label}</FdTypography>
          )}
          {tooltip && (
            <FdTooltip title={tooltip}>
              <IconButton size="small" style={{ marginLeft: '10px' }}>
                <InfoOutlined />
              </IconButton>
            </FdTooltip>
          )}
        </Box>
        <Box>
          <FdTypography variant="body1" color="secondary">
            {value}
          </FdTypography>
        </Box>
      </Box>
    )
  );
};

FdTextView.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  hideOnEmpty: PropTypes.bool,
  tooltip: PropTypes.node,
  noHeading: PropTypes.bool,
};

FdTextView.defaultProps = {
  label: '',
  value: '',
  hideOnEmpty: false,
  tooltip: undefined,
  noHeading: false,
};

export default FdTextView;
