import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, TextField } from '@material-ui/core';
import { FdTable, FdModal, FdAutocomplete } from '@fifthdomain/fe-shared';
import { challengeInfo } from './columns';
import { getTagColor } from '../../shared/utils/tagUtils';

const BulkAddTags = ({
  rows,
  allTags,
  bulkAddTagModal,
  onConfirm,
  onDismiss,
  tagsSelected,
  setTagsSelected,
}) => {
  return (
    <FdModal
      title="Bulk Add Tags"
      description="Select tags to add to all selected challenges"
      confirm="Save"
      dismiss="Cancel"
      showDismiss
      open={bulkAddTagModal}
      onConfirm={onConfirm}
      setOpen={bulkAddTagModal}
      onDismiss={onDismiss}
      maxWidth="md"
      disableConfirm={tagsSelected?.length === 0}
    >
      <Box height="400px">
        <FdTable
          title=" "
          toolbarSettings={{
            hide: true,
          }}
          rows={rows}
          columns={[
            { field: 'order', width: 50, headerName: '' },
            challengeInfo,
            {
              field: 'category',
              width: 150,
              headerName: 'Category',
            },
          ]}
          tablePageSize={5}
          gridId="tasks-list-tags"
        />
      </Box>
      <Box height="200px" mt={2}>
        <FdAutocomplete
          label="Add Tags"
          helperText="Type in existing tag names to apply them to selected challenges."
          options={allTags?.map((at) => ({
            name: at.name,
            id: at.id,
            color: at.color,
            displayColor: getTagColor(at.color),
          }))}
          multiple
          fullWidth
          selectAllLabel="Select all"
          optionLabel="name"
          optionValue="id"
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                style={{
                  backgroundColor: getTagColor(option.color),
                }}
              />
            ))
          }
          ListItemTextRenderer={({ option }) => {
            return (
              <Chip
                label={option.name}
                style={{
                  backgroundColor: option.displayColor,
                }}
              />
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Type to Add an Existing Tag"
            />
          )}
          onChange={(_val) => setTagsSelected(_val)}
        />
      </Box>
    </FdModal>
  );
};

BulkAddTags.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bulkAddTagModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  tagsSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTagsSelected: PropTypes.func.isRequired,
};

export default BulkAddTags;
