import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FdCard, FdTextField } from '@fifthdomain/fe-shared';

const Flag = () => {
  const { control } = useFormContext();

  return (
    <FdCard
      heading="Flag"
      subHeading="Enter the flag for the challenge in the format FLAG{text}"
      variant="outlined"
    >
      <Controller
        control={control}
        name="flag"
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <FdTextField
            id="flag"
            label="Flag"
            required
            error={error}
            helperText={error ? error.message : null}
            placeholder="FLAG{text}"
            {...rest}
            inputRef={ref}
          />
        )}
      />
    </FdCard>
  );
};

export default Flag;
