/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const attempt = /* GraphQL */ `
  mutation Attempt($taskId: ID!, $userAssessmentId: ID!, $answer: String!) {
    attempt(
      taskId: $taskId
      userAssessmentId: $userAssessmentId
      answer: $answer
    )
  }
`;
export const startAssessmentForUser = /* GraphQL */ `
  mutation StartAssessmentForUser($userAssessmentId: ID!) {
    startAssessmentForUser(userAssessmentId: $userAssessmentId)
  }
`;
export const stopAssessmentForUser = /* GraphQL */ `
  mutation StopAssessmentForUser($userAssessmentId: ID!) {
    stopAssessmentForUser(userAssessmentId: $userAssessmentId)
  }
`;
export const createNewAssessment = /* GraphQL */ `
  mutation CreateNewAssessment($assesssment: NewAssessment!) {
    createNewAssessment(assesssment: $assesssment)
  }
`;
export const editAssessment = /* GraphQL */ `
  mutation EditAssessment($assesssment: EditAssessment!) {
    editAssessment(assesssment: $assesssment)
  }
`;
export const startGroupAssessment = /* GraphQL */ `
  mutation StartGroupAssessment($groupId: ID!, $assessmentId: ID!) {
    startGroupAssessment(groupId: $groupId, assessmentId: $assessmentId)
  }
`;
export const stopGroupAssessment = /* GraphQL */ `
  mutation StopGroupAssessment($groupId: ID!, $assessmentId: ID!) {
    stopGroupAssessment(groupId: $groupId, assessmentId: $assessmentId)
  }
`;
export const addUsers = /* GraphQL */ `
  mutation AddUsers(
    $emails: [AWSEmail]
    $orgId: ID!
    $type: USER_TYPE!
    $participantType: PARTICIPANT_TYPE
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $permissions: [PERMISSION_TYPE]
    $assessmentId: ID
    $groups: [ID]
  ) {
    addUsers(
      emails: $emails
      orgId: $orgId
      type: $type
      participantType: $participantType
      participantEventType: $participantEventType
      permissions: $permissions
      assessmentId: $assessmentId
      groups: $groups
    )
  }
`;
export const addUser = /* GraphQL */ `
  mutation AddUser(
    $email: String!
    $orgId: ID!
    $type: USER_TYPE!
    $participantType: PARTICIPANT_TYPE
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $permissions: [PERMISSION_TYPE]
    $assessmentId: ID
  ) {
    addUser(
      email: $email
      orgId: $orgId
      type: $type
      participantType: $participantType
      participantEventType: $participantEventType
      permissions: $permissions
      assessmentId: $assessmentId
    )
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: EditUser!) {
    updateUser(input: $input)
  }
`;
export const removeUserFromAssessment = /* GraphQL */ `
  mutation RemoveUserFromAssessment($userAssessmentId: ID!) {
    removeUserFromAssessment(userAssessmentId: $userAssessmentId)
  }
`;
export const removeInvitedUser = /* GraphQL */ `
  mutation RemoveInvitedUser($inviteToken: ID!) {
    removeInvitedUser(inviteToken: $inviteToken)
  }
`;
export const createNewOnboarding = /* GraphQL */ `
  mutation CreateNewOnboarding($input: CreateOnboardingInput!) {
    createNewOnboarding(input: $input)
  }
`;
export const updateRoleAndPermissions = /* GraphQL */ `
  mutation UpdateRoleAndPermissions(
    $emails: [AWSEmail]!
    $type: USER_TYPE!
    $permissions: [PERMISSION_TYPE]
  ) {
    updateRoleAndPermissions(
      emails: $emails
      type: $type
      permissions: $permissions
    )
  }
`;
export const manageUserAndGroup = /* GraphQL */ `
  mutation ManageUserAndGroup(
    $groupId: ID!
    $userIds: [ID!]
    $operation: OPERATION_TYPE!
  ) {
    manageUserAndGroup(
      groupId: $groupId
      userIds: $userIds
      operation: $operation
    )
  }
`;
export const removeUserFromOrg = /* GraphQL */ `
  mutation RemoveUserFromOrg($email: AWSEmail!) {
    removeUserFromOrg(email: $email)
  }
`;
export const removeGroupsFromAssessment = /* GraphQL */ `
  mutation RemoveGroupsFromAssessment($groupIds: [ID]!, $assessmentId: ID!) {
    removeGroupsFromAssessment(groupIds: $groupIds, assessmentId: $assessmentId)
  }
`;
export const migrateParticipant = /* GraphQL */ `
  mutation MigrateParticipant($users: [MigratedParticipant]) {
    migrateParticipant(users: $users)
  }
`;
export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment(
    $input: CreateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    createAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment(
    $input: UpdateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    updateAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssessment = /* GraphQL */ `
  mutation DeleteAssessment(
    $input: DeleteAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    deleteAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      name
      description
      summary
      type
      labId
      externalId
      orgId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id

        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          action
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      name
      description
      summary
      type
      labId
      externalId
      orgId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      solutionVideo
      solutionSteps
      specialtyId
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          action
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      name
      description
      type
      externalId
      orgId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        type
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          action
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      taskId
      userId
      comment
      createdAt
      updatedAt
    }
  }
`;
export const updateHistory = /* GraphQL */ `
  mutation UpdateHistory(
    $input: UpdateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    updateHistory(input: $input, condition: $condition) {
      id
      taskId
      userId
      action
      comment
      createdAt
      updatedAt
    }
  }
`;
export const deleteHistory = /* GraphQL */ `
  mutation DeleteHistory(
    $input: DeleteHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    deleteHistory(input: $input, condition: $condition) {
      id
      taskId
      userId
      action
      comment
      createdAt
      updatedAt
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHint = /* GraphQL */ `
  mutation CreateHint(
    $input: CreateHintInput!
    $condition: ModelHintConditionInput
  ) {
    createHint(input: $input, condition: $condition) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const updateHint = /* GraphQL */ `
  mutation UpdateHint(
    $input: UpdateHintInput!
    $condition: ModelHintConditionInput
  ) {
    updateHint(input: $input, condition: $condition) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const deleteHint = /* GraphQL */ `
  mutation DeleteHint(
    $input: DeleteHintInput!
    $condition: ModelHintConditionInput
  ) {
    deleteHint(input: $input, condition: $condition) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const createTaskAssessment = /* GraphQL */ `
  mutation CreateTaskAssessment(
    $input: CreateTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    createTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      modulePartId
      modulePart {
        id
        duration
        expiry
        inactivityExpiry
        initialLabCount
        minLabCount
        deletionMode
        labId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTaskAssessment = /* GraphQL */ `
  mutation UpdateTaskAssessment(
    $input: UpdateTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    updateTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaskAssessment = /* GraphQL */ `
  mutation DeleteTaskAssessment(
    $input: DeleteTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    deleteTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTaskAttempt = /* GraphQL */ `
  mutation CreateTaskAttempt(
    $input: CreateTaskAttemptInput!
    $condition: ModelTaskAttemptConditionInput
  ) {
    createTaskAttempt(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const updateTaskAttempt = /* GraphQL */ `
  mutation UpdateTaskAttempt(
    $input: UpdateTaskAttemptInput!
    $condition: ModelTaskAttemptConditionInput
  ) {
    updateTaskAttempt(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const deleteTaskAttempt = /* GraphQL */ `
  mutation DeleteTaskAttempt(
    $input: DeleteTaskAttemptInput!
    $condition: ModelTaskAttemptConditionInput
  ) {
    deleteTaskAttempt(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const createTaskOpened = /* GraphQL */ `
  mutation CreateTaskOpened(
    $input: CreateTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    createTaskOpened(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const updateTaskOpened = /* GraphQL */ `
  mutation UpdateTaskOpened(
    $input: UpdateTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    updateTaskOpened(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const deleteTaskOpened = /* GraphQL */ `
  mutation DeleteTaskOpened(
    $input: DeleteTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    deleteTaskOpened(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const createCompetency = /* GraphQL */ `
  mutation CreateCompetency(
    $input: CreateCompetencyInput!
    $condition: ModelCompetencyConditionInput
  ) {
    createCompetency(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateCompetency = /* GraphQL */ `
  mutation UpdateCompetency(
    $input: UpdateCompetencyInput!
    $condition: ModelCompetencyConditionInput
  ) {
    updateCompetency(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteCompetency = /* GraphQL */ `
  mutation DeleteCompetency(
    $input: DeleteCompetencyInput!
    $condition: ModelCompetencyConditionInput
  ) {
    deleteCompetency(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createCompetencyTask = /* GraphQL */ `
  mutation CreateCompetencyTask(
    $input: CreateCompetencyTaskInput!
    $condition: ModelCompetencyTaskConditionInput
  ) {
    createCompetencyTask(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateCompetencyTask = /* GraphQL */ `
  mutation UpdateCompetencyTask(
    $input: UpdateCompetencyTaskInput!
    $condition: ModelCompetencyTaskConditionInput
  ) {
    updateCompetencyTask(input: $input, condition: $condition) {
      id
      competencyId
      taskId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompetencyTask = /* GraphQL */ `
  mutation DeleteCompetencyTask(
    $input: DeleteCompetencyTaskInput!
    $condition: ModelCompetencyTaskConditionInput
  ) {
    deleteCompetencyTask(input: $input, condition: $condition) {
      id
      competencyId
      taskId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOrg = /* GraphQL */ `
  mutation CreateOrg(
    $input: CreateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    createOrg(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrg = /* GraphQL */ `
  mutation UpdateOrg(
    $input: UpdateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    updateOrg(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrg = /* GraphQL */ `
  mutation DeleteOrg(
    $input: DeleteOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    deleteOrg(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserConsent = /* GraphQL */ `
  mutation CreateUserConsent(
    $input: CreateUserConsentInput!
    $condition: ModelUserConsentConditionInput
  ) {
    createUserConsent(input: $input, condition: $condition) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateUserConsent = /* GraphQL */ `
  mutation UpdateUserConsent(
    $input: UpdateUserConsentInput!
    $condition: ModelUserConsentConditionInput
  ) {
    updateUserConsent(input: $input, condition: $condition) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserConsent = /* GraphQL */ `
  mutation DeleteUserConsent(
    $input: DeleteUserConsentInput!
    $condition: ModelUserConsentConditionInput
  ) {
    deleteUserConsent(input: $input, condition: $condition) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const createConsent = /* GraphQL */ `
  mutation CreateConsent(
    $input: CreateConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    createConsent(input: $input, condition: $condition) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateConsent = /* GraphQL */ `
  mutation UpdateConsent(
    $input: UpdateConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    updateConsent(input: $input, condition: $condition) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsent = /* GraphQL */ `
  mutation DeleteConsent(
    $input: DeleteConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    deleteConsent(input: $input, condition: $condition) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroupAssessment = /* GraphQL */ `
  mutation CreateGroupAssessment(
    $input: CreateGroupAssessmentInput!
    $condition: ModelGroupAssessmentConditionInput
  ) {
    createGroupAssessment(input: $input, condition: $condition) {
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupAssessment = /* GraphQL */ `
  mutation UpdateGroupAssessment(
    $input: UpdateGroupAssessmentInput!
    $condition: ModelGroupAssessmentConditionInput
  ) {
    updateGroupAssessment(input: $input, condition: $condition) {
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupAssessment = /* GraphQL */ `
  mutation DeleteGroupAssessment(
    $input: DeleteGroupAssessmentInput!
    $condition: ModelGroupAssessmentConditionInput
  ) {
    deleteGroupAssessment(input: $input, condition: $condition) {
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;

export const createModulePart = /* GraphQL */ `
  mutation CreateModulePart(
    $input: CreateModulePartInput!
    $condition: ModelModulePartConditionInput
  ) {
    createModulePart(input: $input, condition: $condition) {
      name
      description
      courseModuleId
      type
      quizId
      labId
      duration
      expiry
      inactivityExpiry
      initialLabCount
      minLabCount
      url
      id
      createdAt
      updatedAt
      courseModulePartsId
    }
  }
`;

export const updateModulePart = /* GraphQL */ `
  mutation UpdateModulePart(
    $input: UpdateModulePartInput!
    $condition: ModelModulePartConditionInput
  ) {
    updateModulePart(input: $input, condition: $condition) {
      name
      description
      courseModuleId
      type
      quizId
      labId
      duration
      expiry
      inactivityExpiry
      initialLabCount
      minLabCount
      url
      id
      createdAt
      updatedAt
      courseModulePartsId
    }
  }
`;
export const manageTags = /* GraphQL */ `
  mutation ManageTags(
    $action: ACTION_TYPE!
    $taskIds: [ID]!
    $tagIds: [ID]!
    $orgId: ID
  ) {
    manageTags(
      action: $action
      taskIds: $taskIds
      tagIds: $tagIds
      orgId: $orgId
    )
  }
`;

export const transferTaskOwnership = /* GraphQL */ `
  mutation TransferTaskOwnership($orgAlias: String!, $taskIds: [ID]!) {
    transferTaskOwnership(orgAlias: $orgAlias, taskIds: $taskIds)
  }
`;

export const createTaskSkill = /* GraphQL */ `
  mutation CreateTaskSkill(
    $input: CreateTaskSkillInput!
    $condition: ModelTaskSkillConditionInput
  ) {
    createTaskSkill(input: $input, condition: $condition) {
      id
      skillId
      taskId
      techniqueTags {
        items {
          id
          taskSkillID
          techniqueTagID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createTaskSkillTechniqueTag = /* GraphQL */ `
  mutation CreateTaskSkillTechniqueTag(
    $input: CreateTaskSkillTechniqueTagInput!
    $condition: ModelTaskSkillTechniqueTagConditionInput
  ) {
    createTaskSkillTechniqueTag(input: $input, condition: $condition) {
      id
      taskSkillID
      techniqueTagID
      createdAt
      updatedAt
    }
  }
`;

export const createTechniqueTag = /* GraphQL */ `
  mutation CreateTechniqueTag(
    $input: CreateTechniqueTagInput!
    $condition: ModelTechniqueTagConditionInput
  ) {
    createTechniqueTag(input: $input, condition: $condition) {
      id
      name
      description
      isPreset
      userId
      taskSkills {
        items {
          id
          taskSkillID
          techniqueTagID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createTechnologyTag = /* GraphQL */ `
  mutation CreateTechnologyTag(
    $input: CreateTechnologyTagInput!
    $condition: ModelTechnologyTagConditionInput
  ) {
    createTechnologyTag(input: $input, condition: $condition) {
      id
      name
      description
      userId
      tasks {
        items {
          id
          taskID
          technologyTagID
          createdAt
          updatedAt
          userId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createTaskTechnologyTag = /* GraphQL */ `
  mutation CreateTaskTechnologyTag(
    $input: CreateTaskTechnologyTagInput!
    $condition: ModelTaskTechnologyTagConditionInput
  ) {
    createTaskTechnologyTag(input: $input, condition: $condition) {
      id
      taskID
      technologyTagID
      task {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userId
    }
  }
`;

export const deleteTaskSkill = /* GraphQL */ `
  mutation DeleteTaskSkill(
    $input: DeleteTaskSkillInput!
    $condition: ModelTaskSkillConditionInput
  ) {
    deleteTaskSkill(input: $input, condition: $condition) {
      id
      skillId
      taskId
    }
  }
`;

export const deleteTaskSkillTechniqueTag = /* GraphQL */ `
  mutation DeleteTaskSkillTechniqueTag(
    $input: DeleteTaskSkillTechniqueTagInput!
    $condition: ModelTaskSkillTechniqueTagConditionInput
  ) {
    deleteTaskSkillTechniqueTag(input: $input, condition: $condition) {
      id
      taskSkillID
      techniqueTagID
      createdAt
      updatedAt
    }
  }
`;

export const deleteTaskTechnologyTag = /* GraphQL */ `
  mutation DeleteTaskTechnologyTag(
    $input: DeleteTaskTechnologyTagInput!
    $condition: ModelTaskTechnologyTagConditionInput
  ) {
    deleteTaskTechnologyTag(input: $input, condition: $condition) {
      id
      taskID
      technologyTagID
    }
  }
`;

export const createTaskReviewer = /* GraphQL */ `
  mutation CreateTaskReviewer(
    $input: CreateTaskReviewerInput!
    $condition: ModelTaskReviewerConditionInput
  ) {
    createTaskReviewer(input: $input, condition: $condition) {
      id
      taskId
      userId
      assigneeId
    }
  }
`;

export const deleteTaskReviewer = /* GraphQL */ `
  mutation DeleteTaskReviewer(
    $input: DeleteTaskReviewerInput!
    $condition: ModelTaskReviewerConditionInput
  ) {
    deleteTaskReviewer(input: $input, condition: $condition) {
      id
      taskId
      userId
      assigneeId
      assignee {
        name
      }
    }
  }
`;
export const startLab = /* GraphQL */ `
  mutation StartLab($labPrototypeId: ID, $modulePartId: ID, $assessmentId: ID) {
    startLab(
      labPrototypeId: $labPrototypeId
      modulePartId: $modulePartId
      assessmentId: $assessmentId
    )
  }
`;

export const stopLab = /* GraphQL */ `
  mutation StopLab($labInstanceId: ID) {
    stopLab(labInstanceId: $labInstanceId)
  }
`;
