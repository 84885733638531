export const USER_PERMISSION_TYPE = Object.freeze({
  FDADMIN: 'fd-admin',
  MANAGE_GROUPS: 'manage-groups',
  MANAGE_ADMINS: 'manage-admins',
  MANAGE_TRAINING: 'manage-training',
  MANAGE_TAGS: 'manage-tags',
  CREATE: 'create',
  VIEW_INSIGHTS: 'view-insights',
});

export const CHALLENGE_TYPE = Object.freeze({
  LAB_BASED: 'Lab-Based',
});

export const TAG_COLOUR = [
  {
    backgroundColor: '#FFE082',
    value: 'YELLOW',
  },
  {
    backgroundColor: '#FFAB91',
    value: 'ORANGE',
  },
  {
    backgroundColor: '#F48FB1',
    value: 'PINK',
  },
  {
    backgroundColor: '#CE93D8',
    value: 'PURPLE',
  },
  {
    backgroundColor: '#40C4FF',
    value: 'BLUE',
  },
  {
    backgroundColor: '#80CBC4',
    value: 'TEAL',
  },
];

export const SPECIALTY_COLOR = {
  Intelligence: 'rgba(163, 40, 237, 1)',
  Protection: 'rgba(78, 78, 255, 1)',
  Detection: 'rgba(200, 73, 164, 1)',
  Investigation: 'rgba(62, 179, 214, 1)',
  Penetration: 'rgba(151, 177, 255, 1)',
  Engineering: 'rgba(164, 184, 209, 1)',
};

export const PROFICIENCY = {
  Novice: {
    color: 'rgba(118, 209, 165, 1)',
    range: '1-2',
    level: 1,
    points: 50,
  },
  'Advanced Beginner': {
    color: 'rgba(151, 180, 255, 1)',
    range: '3-4',
    level: 2,
    points: 80,
  },
  Competent: {
    color: 'rgba(246, 185, 113, 1)',
    range: '5-6',
    level: 3,
    points: 130,
  },
  Proficient: {
    color: 'rgba(227, 128, 187, 1)',
    range: '7-8',
    level: 4,
    points: 210,
  },
  Expert: {
    color: 'rgba(213, 83, 83, 1)',
    range: '9-10',
    level: 5,
    points: 340,
  },
};

export const PROFICIENCY_LEVELS = {
  1: 'Novice',
  2: 'Advanced Beginner',
  3: 'Competent',
  4: 'Proficient',
  5: 'Expert',
};
