export const createMultipleHints = async (
  createHintMutation,
  _taskId,
  hintValues,
) => {
  await Promise.all(
    hintValues.map((object) => {
      return createHintMutation({
        variables: {
          input: {
            taskId: _taskId,
            text: object.description,
          },
        },
      });
    }),
  );
};

export const updateMultipleHints = async (
  updateHintMutation,
  _taskId,
  hintValues,
) => {
  await Promise.all(
    hintValues.map((object) => {
      return updateHintMutation({
        variables: {
          input: {
            id: object.id,
            taskId: _taskId,
            text: object.description,
          },
        },
      });
    }),
  );
};

export const createMultipleCompetencies = async (
  createCompetencyTaskMutation,
  _taskId,
  competencyValues,
) => {
  await Promise.all(
    competencyValues.map((competency) => {
      const { id, categoryId, workRoleId, areaId } = competency ?? {};
      return createCompetencyTaskMutation({
        variables: {
          input: {
            taskId: _taskId,
            competencyId: id,
            categoryId,
            workRoleId,
            areaId,
          },
        },
      });
    }),
  );
};

export const createMultipleAttachments = async (
  createFileMutation,
  amplifyConfig,
  _taskId,
  uploadAttachments,
) => {
  await Promise.all(
    uploadAttachments.map((object) => {
      return createFileMutation({
        variables: {
          input: {
            taskId: _taskId,
            url: object.url,
            name: object.name,
            size: object.fileSize,
            type: object.fileType,
            file: {
              bucket: amplifyConfig.aws_user_files_s3_bucket,
              key: `public/${object.name}`,
              region: amplifyConfig.aws_user_files_s3_bucket_region,
            },
          },
        },
      });
    }),
  );
};

export const createMultipleTechnologyTags = (
  createTechnologyTagMutation,
  createTaskTechnologyTagMutation,
  userId,
  _taskId,
  technologyTags,
) => {
  Promise.all(
    technologyTags?.map((technologyTag) => {
      if (
        !technologyTags.some(
          (exisingTechTag) => exisingTechTag.technologyId === technologyTag?.id,
        )
      ) {
        return createTechnologyTagMutation({
          variables: {
            input: {
              name: technologyTag.name,
              description: technologyTag.name,
              userId,
            },
          },
          onCompleted: (_tag) =>
            createTaskTechnologyTagMutation({
              variables: {
                input: {
                  taskID: _taskId,
                  technologyTagID: _tag?.createTechnologyTag?.id,
                },
              },
            }),
        });
      }

      return createTaskTechnologyTagMutation({
        variables: {
          input: {
            taskID: _taskId,
            technologyTagID: technologyTag?.technologyId,
          },
        },
      });
    }),
  );
};

export const removeMultipleSkills = (
  deleteTaskSkillMutation,
  deleteTaskSkillTechniqueTagMutation,
  skills,
) => {
  Promise.all(
    skills?.map((skill) =>
      deleteTaskSkillMutation({
        variables: {
          input: { id: skill.dbId },
        },
        onCompleted: () => {
          Promise.all(
            skill?.techniqueTags?.map((techniqueTag) => {
              return deleteTaskSkillTechniqueTagMutation({
                variables: {
                  input: { id: techniqueTag.dbId },
                },
              });
            }),
          );
        },
      }),
    ),
  );
};

export const removeMultipleTechnologyTags = (
  deleteTaskTechnologyTagMutation,
  technologyTags,
) =>
  Promise.all(
    technologyTags?.map((technologyTag) =>
      deleteTaskTechnologyTagMutation({
        variables: {
          input: {
            id: technologyTag.dbId,
          },
        },
      }),
    ),
  );

export const updateExistingSkills = (
  deleteTaskSkillTechniqueTagMutation,
  prevSkills,
  techniqueTags,
  createTechniqueTagMutation,
  userId,
  createTaskSkillTechniqueTagMutation,
  existingSkills = [],
) => {
  existingSkills?.map((_skill) => {
    const prevSkillTechniqueTags = prevSkills.find(
      (prevSkill) => prevSkill.skillId === _skill.skillId,
    )?.techniqueTags;
    const deletingTechniqueTags = prevSkillTechniqueTags.filter(
      (tag) =>
        !_skill?.techniqueTags.some(
          (techniqueTag) => techniqueTag.techniqueId === tag.techniqueId,
        ),
    );
    if (deletingTechniqueTags?.length > 0) {
      return Promise.all(
        deletingTechniqueTags.map((tag) => {
          return deleteTaskSkillTechniqueTagMutation({
            variables: {
              input: { id: tag.dbId },
            },
          });
        }),
      );
    }

    return Promise.all(
      // eslint-disable-next-line array-callback-return, consistent-return
      _skill.techniqueTags?.map((tag) => {
        if (
          !prevSkillTechniqueTags?.some(
            (prevTag) => prevTag.techniqueId === tag.techniqueId,
          ) &&
          !techniqueTags.some(
            (existingTag) => existingTag.techniqueId === tag.techniqueId,
          )
        ) {
          return createTechniqueTagMutation({
            variables: {
              input: {
                name: tag.name,
                description: tag.name,
                userId,
              },
            },
            onCompleted: (_tag) => {
              createTaskSkillTechniqueTagMutation({
                variables: {
                  input: {
                    taskSkillID: _skill.dbId,
                    techniqueTagID: _tag?.createTechniqueTag?.id,
                  },
                },
              });
            },
          });
        }

        if (
          !prevSkillTechniqueTags?.some(
            (prevTag) => prevTag.techniqueId === tag.techniqueId,
          )
        ) {
          return createTaskSkillTechniqueTagMutation({
            variables: {
              input: {
                taskSkillID: _skill.dbId,
                techniqueTagID: tag.techniqueId,
              },
            },
          });
        }
      }),
    );
  });
};

export const createMultipleSkills = (
  createTaskSkillMutation,
  techniqueTags,
  createTechniqueTagMutation,
  createTaskSkillTechniqueTagMutation,
  userId,
  taskId,
  skills,
) => {
  Promise.all(
    skills?.map((skill) =>
      createTaskSkillMutation({
        variables: {
          input: {
            taskId,
            skillId: skill.skillId,
          },
        },
        onCompleted: (taskSkill) => {
          Promise.all(
            skill?.techniqueTags?.map((techniqueTag) => {
              if (
                !techniqueTags.some(
                  (existingTag) => existingTag.id === techniqueTag.id,
                )
              ) {
                return createTechniqueTagMutation({
                  variables: {
                    input: {
                      name: techniqueTag.name,
                      description: techniqueTag.name,
                      userId,
                    },
                  },
                  onCompleted: (_tag) =>
                    createTaskSkillTechniqueTagMutation({
                      variables: {
                        input: {
                          taskSkillID: taskSkill?.createTaskSkill?.id,
                          techniqueTagID: _tag?.createTechniqueTag?.id,
                        },
                      },
                    }),
                });
              }

              return createTaskSkillTechniqueTagMutation({
                variables: {
                  input: {
                    taskSkillID: taskSkill?.createTaskSkill?.id,
                    techniqueTagID: techniqueTag.id,
                  },
                },
              });
            }),
          );
        },
      }),
    ),
  );
};

export const generateHistory = ({
  createHistoryMutation,
  action,
  comment = '',
  taskId,
  userId,
  assigneeId,
}) => {
  // create an history entry
  createHistoryMutation({
    variables: {
      input: {
        action,
        comment,
        taskId,
        userId,
        assigneeId,
      },
    },
  });
};

export const createTaskReviewers = (
  createTaskReviewerMutation,
  reviewers,
  taskId,
  userId,
) =>
  Promise.all(
    reviewers?.map((r) =>
      createTaskReviewerMutation({
        variables: {
          input: {
            assigneeId: r?.id,
            status: 'PENDING',
            taskId,
            userId,
          },
        },
      }),
    ),
  );
