import * as Yup from 'yup';

export const initialValues = {
  name: '',
  description: '',
  summary: '',
  difficulty: '',
  estimatedSolveTime: '',
  selectedCompetencies: [],
  hints: [],
  flag: '',
  uploadAttachments: [],
  uploadSolnAttachments: [],
  solnWalkthrough: '',
  solnVideo: '',
  solnSteps: '',
  hint: '',
  status: '',
  challengeType: {},
  partId: '',
  lab: '',
  specialty: {},
  skills: [],
  technologyTags: [],
  reviewers: [],
  chat: [],
};

const fileArrayShape = {
  fileSize: Yup.string(),
  fileType: Yup.string(),
  id: Yup.string(),
  name: Yup.string(),
  url: Yup.string(),
  size: Yup.string(),
};
// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'noWhitespace', function (message) {
  return this.test({
    name: 'noWhitespace',
    message,
    test: (value) => value && value.trim()?.length,
  });
});

export const validationSchema = Yup.object().shape(
  {
    name: Yup.string()
      .required('Name is required')
      .noWhitespace('Enter a valid name')
      .max(200, 'Name maximum length is 200 characters'),
    description: Yup.string()
      .required('Description is required')
      .noWhitespace('Enter a valid description'),
    summary: Yup.string()
      .required('Summary is required')
      .nullable()
      .noWhitespace('Enter a valid summary')
      .max(550, 'Summary maximum length is 550 characters'),
    difficulty: Yup.string().required('Please select the Proficiency Level'),
    estimatedSolveTime: Yup.number()
      .typeError('Estimated Time to Solve is required')
      .positive('Only positive number accepted')
      .integer('Only positive whole numbers accepted')
      .required('Estimated Time to Solve is required'),
    selectedCompetencies: Yup.array().of(
      Yup.object().shape({
        competency: Yup.string(),
        competencyId: Yup.string(),
        id: Yup.string(),
        categoryId: Yup.string(),
        workRoleId: Yup.string(),
        areaId: Yup.string(),
      }),
    ),
    hints: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        description: Yup.string(),
      }),
    ),
    flag: Yup.string()
      .typeError('Flag is required')
      .required('Flag is required')
      .noWhitespace('Flag is required'),
    uploadAttachments: Yup.array().of(Yup.object().shape(fileArrayShape)),
    solnWalkthrough: Yup.string().required('Solution is required'),
    solnVideo: Yup.string().nullable(),
    solnSteps: Yup.string().nullable(),
    hint: Yup.string(),
    status: Yup.string(),
    partId: Yup.string().nullable(),
    challengeType: Yup.object()
      .shape({ id: Yup.string(), value: Yup.string() })
      .nullable(),
    lab: Yup.string().nullable(),
    specialty: Yup.object()
      .nullable()
      .shape({
        id: Yup.string(),
        value: Yup.string(),
        optionData: Yup.object().shape({}),
      })
      .test(
        'is-null',
        'Please Select Professional Specialty',
        (value) => value !== null,
      ),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          taskSkillId: Yup.string().nullable(),
          id: Yup.string(),
          techniqueTags: Yup.array()
            .of(
              Yup.object().shape({
                dbId: Yup.string().nullable(),
                techniqueId: Yup.string().nullable(),
                name: Yup.string(),
              }),
            )
            .min(1, 'Please add at least one technique'),
        }),
      )
      .min(1, 'Please select at least one skill'),
    technologyTags: Yup.array()
      .of(
        Yup.object().shape({
          dbId: Yup.string().nullable(),
          technologyId: Yup.string().nullable(),
          name: Yup.string(),
        }),
      )
      .min(1, 'Please add at least one Technology'),
    reviewers: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
          email: Yup.string(),
        }),
      )
      .max(5, 'You can select up to 5 reviewers at one time for a challenge.'),
    chat: Yup.array().of(
      Yup.object().shape({
        historyId: Yup.string(),
        userName: Yup.string(),
        comment: Yup.string(),
        assigneeId: Yup.string(),
        assigneeName: Yup.string(),
        assignedBy: Yup.string(),
      }),
    ),
  },
  [['uploadSolnAttachments', 'solnWalkthrough']],
);
