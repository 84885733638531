export const upperCaseFirstLetter = (str) =>
  str && str[0].toUpperCase() + str.substring(1).toLowerCase();

export const getCommaSeparated = (arr, includeQuotes = false) => {
  const arrQ = includeQuotes ? arr?.map((a) => `"${a}"`) : arr;
  if (arrQ?.length === 1) return arrQ[0];
  const firsts = arrQ?.slice(0, arrQ.length - 1);
  const last = arrQ?.[arrQ.length - 1];
  return `${firsts?.join(', ')} and ${last}`;
};

export const truncateTextByLength = (text, maxLength = text?.length) => {
  if (!text) {
    return '';
  }
  if (text.length <= maxLength) {
    return text;
  }
  const truncatedText = `${text.substring(0, maxLength - 3)}...`;
  return truncatedText;
};

// input string array
// output - "Intrusion Detection +2" or "Intrusion Detection, Firewall, VPN, +2"
export const getCommaSeparatedPlusSuffix = (strings = [], showItems = 1) => {
  if (strings.length <= showItems) {
    return strings.join(', ');
  }
  const extraValues = strings.length - showItems;
  const printedValues = strings.slice(0, showItems);
  return `${printedValues} +${extraValues}`;
};
