import React from 'react';
import { Box } from '@material-ui/core';
import { gql } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FdTypography,
  useQueryRecursive,
  FdSkeleton,
  FdButton,
  FdIconsV5,
} from '@fifthdomain/fe-shared';
import EditContainer from './EditContainer';
import Competencies from '../Competencies';
import {
  listSpecialties,
  listTechniqueTags,
  listTechnologyTags,
} from '../../../graphql/queries';
import Hints from '../Hints';
import SolutionWalkthrough from '../SolutionWalkthrough';

const FieldContainer = ({ width, children }) => (
  <Box mt={0} mb={2} style={{ width }}>
    {children}
  </Box>
);

FieldContainer.propTypes = {
  width: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

const MappingAndSupportInfo = ({ onSubmit }) => {
  const {
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { LightbulbOutlined } = FdIconsV5;

  const { data: specialties, loading: specialtiesLoading } = useQueryRecursive(
    gql(listSpecialties),
    { staleTime: { minutes: 15 } },
  );

  const { data: technologyTagsData, loading: technologiesLoading } =
    useQueryRecursive(gql(listTechnologyTags), { staleTime: { minutes: 15 } });

  const { data: techniqueTagsData, loading: techniqueTagsLoading } =
    useQueryRecursive(gql(listTechniqueTags), { staleTime: { minutes: 15 } });

  const technologyTags = _.uniqBy(
    technologyTagsData?.listTechnologyTags?.items.map((t) => ({
      ...t,
      technologyId: t.id,
    })) || [],
    (item) => item.name.toLowerCase(),
  );

  const techniqueTags = _.uniqBy(
    techniqueTagsData?.listTechniqueTags?.items.map((t) => ({
      ...t,
      techniqueId: t.id,
    })) || [],
    (item) => item.name.toLowerCase(),
  );
  const loading =
    techniqueTagsLoading || specialtiesLoading || technologiesLoading;

  return (
    <Box className="flex flex-col gap-y-3">
      <EditContainer
        title="Challenge Mapping"
        labelOnly={
          <FdSkeleton loading={loading} height={200}>
            <Box>
              <FdTypography variant="body1" color="secondary">
                Mapping the challenge accurately helps in effective curation of
                challenges for competitions and assessments
              </FdTypography>
              <Competencies
                specialties={specialties?.listSpecialties?.items}
                techniqueTagsData={techniqueTags}
                technologyTagsData={technologyTags}
                disable
                hideLabels
              />
            </Box>
          </FdSkeleton>
        }
        errors={errors}
        triggerFields={['specialty', 'skills', 'technologyTags']}
        trigger={trigger}
        onCancel={() => reset()}
        onSave={onSubmit}
      >
        <FdSkeleton loading={loading} height={200}>
          <FdTypography variant="body1" color="secondary">
            Mapping the challenge accurately helps in effective curation of
            challenges for competitions and assessments
          </FdTypography>
          <Competencies
            specialties={specialties?.listSpecialties?.items}
            techniqueTagsData={techniqueTags}
            technologyTagsData={technologyTags}
            hideLabels
          />
        </FdSkeleton>
      </EditContainer>
      <EditContainer
        title="Hints"
        labelOnly={
          <Box>
            <FdTypography variant="body1" color="secondary">
              Add hints to help participants complete the challenge. You can add
              as many hints as required.
            </FdTypography>
            {getValues('hints')?.map((hint) => (
              <Box className="flex items-center gap-x-2 my-1">
                <LightbulbOutlined />
                <FdTypography variant="body1" color="secondary">
                  {hint?.description}
                </FdTypography>
              </Box>
            ))}
          </Box>
        }
        errors={errors}
        triggerFields={['hints']}
        trigger={trigger}
        onCancel={() => reset()}
        onSave={onSubmit}
      >
        <Hints />
      </EditContainer>
      <EditContainer
        title="Walkthrough guides for the participants"
        labelOnly={
          <Box>
            <FdTypography variant="body1" color="secondary">
              This participant-facing walkthrough provides a comprehensive guide
              to solving the challenge. You can upload a video and add detailed
              step-by-step instructions for participants to reference alongside
              the video.
            </FdTypography>
            {getValues('solnVideo') && (
              <Box mt={2}>
                <FdButton
                  onClick={() =>
                    window.open(getValues('solnVideo'), '_blank', 'noreferrer')
                  }
                >
                  VIEW SOLUTION WALKTHROUGH
                </FdButton>
              </Box>
            )}
          </Box>
        }
        errors={errors}
        triggerFields={['solnVideo', 'solnSteps']}
        trigger={trigger}
        onCancel={() => reset()}
        onSave={onSubmit}
      >
        <SolutionWalkthrough />
      </EditContainer>
    </Box>
  );
};

MappingAndSupportInfo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MappingAndSupportInfo;
