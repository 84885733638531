import React from 'react';
import { Box, IconButton, Divider } from '@material-ui/core';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  FdCard,
  FdRadioGroup,
  FdTypography,
  FdCheckbox,
  FdIconsV5,
  FdTooltip,
  FdAlert,
} from '@fifthdomain/fe-shared';
import TechnologyTags from './TechnologyTags';
import CompetencyTechniques from './CompetencyTechniques';

const Competencies = ({
  preHeader,
  specialties,
  techniqueTagsData,
  technologyTagsData,
  hideLabels,
  disable,
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { InfoOutlined } = FdIconsV5;
  const watchSpecialty = watch('specialty');
  const {
    fields: skills,
    append: appendSkill,
    remove: removeSkill,
  } = useFieldArray({
    control,
    name: 'skills',
  });

  const onSkillChange = (values) => {
    const skillIds = skills.map((s) => s.skillId);
    const addSkill = values.find((value) => !skillIds.includes(value));
    const removeSkillIdx = skills.findIndex((s) => !values.includes(s.skillId));
    if (addSkill) {
      appendSkill({ skillId: addSkill, techniqueTags: [] });
      return;
    }
    removeSkill(removeSkillIdx);
  };

  const specialtyOptions = specialties?.map((specialty) => ({
    specialtyId: specialty?.id,
    name: specialty?.name,
    skills: specialty?.skills?.items?.map((skill) => ({
      skillId: skill.id,
      name: skill?.name,
      description: skill?.description,
    })),
  }));

  const skillOptions = specialtyOptions
    ?.find((sp) => sp.specialtyId === watchSpecialty.optionData?.specialtyId)
    ?.skills?.reduce((acc, skill) => {
      return {
        ...acc,
        [skill.skillId]: { label: skill.name, caption: skill.description },
      };
    }, {});

  return (
    <FdCard
      heading={!hideLabels && 'Challenge Mapping'}
      subHeading={
        !hideLabels &&
        'Mapping the challenge accurately helps in the effective curation of challenges for competitions and assessments'
      }
      preHeader={preHeader}
      variant="outlined"
    >
      <Box>
        <Controller
          control={control}
          name="specialty"
          render={({
            field: { ref, value: fieldValue, ...rest },
            fieldState: { error },
          }) => (
            <Box mt={2} mb={2} ml={1}>
              {errors.skills && (
                <Box mb={2}>
                  <FdAlert
                    message="Please provide challenge mapping"
                    variant="error"
                  />
                </Box>
              )}
              <FdRadioGroup
                id="specialty"
                label={
                  <Box display="flex" alignItems="center">
                    <FdTypography>
                      Which Professional Specialty best aligns with this
                      challenge?
                    </FdTypography>
                    <FdTooltip title="Professional specialties denote broad categories of cyber operations. Each challenge aligns with one of these specialties, providing a structured framework for classification.">
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </FdTooltip>
                  </Box>
                }
                disabled={
                  disable ? specialtyOptions?.map((__, i) => i) : undefined
                }
                name="specialty"
                options={specialtyOptions}
                optionLabel="name"
                defaultValue={fieldValue.value}
                fullWidth
                error={error && error.message}
                data-cy="specialty"
                placement="horizontal"
                blockquote={false}
                {...rest}
                onChange={(v) => {
                  setValue('specialty', v, { shouldDirty: true });
                  setValue('skills', []);
                }}
                inputRef={ref}
              />
            </Box>
          )}
        />
        {watchSpecialty?.value && (
          <>
            <Box display="flex">
              <Divider
                orientation="vertical"
                flexItem
                style={{ width: '4px', marginBottom: '1rem' }}
              />
              <Box display="flex" flexDirection="column" ml={2} pb={1}>
                {watchSpecialty?.value && (
                  <Box display="flex">
                    <FdTypography color="secondary" variant="body1">
                      Please select upto 3 skills under &nbsp;
                      <span style={{ fontWeight: 'bold' }} color="">
                        {watchSpecialty?.value}
                      </span>
                      &nbsp; that are relevant to this challenge.
                    </FdTypography>
                    <FdTooltip title="The skill required to solve a challenge signifies a specific, acquired and demonstrated ability necessary to successfully navigate a challenge within a given timeframe or with a certain amount of effort. The skill set is fixed, and each challenge should only require one or two skills for completion. The list of skills required for each challenge will be tied to the chosen professional specialty, with each specialty having unique skill sets.">
                      <span color="secondary" style={{ paddingLeft: '10px' }}>
                        <InfoOutlined />
                      </span>
                    </FdTooltip>
                  </Box>
                )}
                <Box mt={2} mb={2}>
                  <FdCheckbox
                    name="skills"
                    options={skillOptions}
                    multiple
                    formLegend=""
                    selectAll={false}
                    onChange={onSkillChange}
                    defaultSelected={skills?.map((val) => val?.skillId)}
                    maxSelection={3}
                    error={errors?.skills?.message}
                    helperText={errors?.skills?.message}
                    disabled={disable}
                  />
                </Box>
                {skills?.map((skill, idx) => (
                  <CompetencyTechniques
                    skillIdx={idx}
                    skillName={
                      watchSpecialty?.optionData?.skills.find(
                        (option) => option?.skillId === skill?.skillId,
                      )?.name
                    }
                    techniqueTagsData={techniqueTagsData}
                    disabled={disable}
                    idx={idx}
                  />
                ))}
              </Box>
            </Box>
            <TechnologyTags
              technologyTagsData={technologyTagsData}
              disabled={disable}
            />
          </>
        )}
      </Box>
    </FdCard>
  );
};

Competencies.propTypes = {
  competencies: PropTypes.node.isRequired,
  preHeader: PropTypes.node,
  specialties: PropTypes.arrayOf(PropTypes.shape({})),
  techniqueTagsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  technologyTagsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hideLabels: PropTypes.bool,
  disable: PropTypes.bool,
};

Competencies.defaultProps = {
  specialties: [],
  preHeader: null,
  hideLabels: false,
  disable: false,
};

export default Competencies;
