import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Chip, TextField } from '@material-ui/core';
import {
  FdTable,
  FdModal,
  FdAutocomplete,
  FdTypography,
} from '@fifthdomain/fe-shared';
import { challengeInfo } from './columns';
import { getTagColor } from '../../shared/utils/tagUtils';

const BulkRemoveTags = ({
  rows,
  bulkRemoveTagModal,
  onConfirm,
  onDismiss,
  tagsSelected,
  setTagsSelected,
}) => {
  const getChallengeCount = (_id) =>
    rows?.filter((r) => r.assignedTags?.map((t) => t.id).includes(_id))
      .length || 0;

  const tagsToDelete = rows?.map((r) => r.assignedTags).flat();
  const availableTagsToDelete = _.uniqBy(tagsToDelete, 'id');

  return (
    <FdModal
      title="Bulk Remove Tags"
      description="Select tags to remove to all selected challenges"
      confirm="Save"
      dismiss="Cancel"
      showDismiss
      open={bulkRemoveTagModal}
      onConfirm={onConfirm}
      setOpen={bulkRemoveTagModal}
      onDismiss={onDismiss}
      maxWidth="md"
      disableConfirm={tagsSelected?.length === 0}
    >
      <Box height="400px">
        <FdTable
          title=" "
          toolbarSettings={{
            hide: true,
          }}
          rows={rows}
          columns={[
            { field: 'order', width: 50, headerName: '' },
            challengeInfo,
            {
              field: 'category',
              width: 150,
              headerName: 'Category',
            },
          ]}
          tablePageSize={5}
          gridId="tasks-list-tags-remove"
        />
      </Box>
      <Box height="200px" mt={2}>
        <FdAutocomplete
          label="Remove Tags"
          helperText="Select tags to remove them from selected challenges with the tags applied to them. Note that changes will not be made until the Save button is clicked. "
          options={availableTagsToDelete?.map((at) => ({
            name: at.name,
            id: at.id,
            color: at.color,
            displayColor: getTagColor(at.color),
          }))}
          multiple
          fullWidth
          selectAllLabel="Select all"
          optionLabel="name"
          optionValue="id"
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                style={{
                  backgroundColor: getTagColor(option.color),
                }}
              />
            ))
          }
          ListItemTextRenderer={({ option }) => {
            const challengeCount = getChallengeCount(option.id);
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Chip
                  label={option.name}
                  style={{
                    backgroundColor: option.displayColor,
                  }}
                />
                <Box mr={1}>
                  <FdTypography variant="subtitle2">{`${challengeCount} challenges`}</FdTypography>
                </Box>
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Type or Select Tags to be Removed"
            />
          )}
          defaultSelected={tagsSelected}
          onChange={(_val) => setTagsSelected(_val)}
        />
      </Box>
    </FdModal>
  );
};

BulkRemoveTags.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bulkRemoveTagModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  tagsSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTagsSelected: PropTypes.func.isRequired,
};

export default BulkRemoveTags;
