import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  BasePage,
  BasePageButtonContainer,
  FdButton,
  FdModal,
  FdAlert,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  amplifyConfig,
  FdChip,
  FdSkeleton,
  FdTooltip,
  FdProgress,
  Authorization,
} from '@fifthdomain/fe-shared';
import {
  Details,
  Competencies,
  Flag,
  Hints,
  Attachments,
  Lab,
  Solution,
  SolutionWalkthrough,
} from '../components/Task';
import {
  successToastMessage,
  warningToastMessage,
  errorToastMessage,
} from '../shared/utils/toast';
import {
  createTask,
  createHint,
  createCompetencyTask,
  updateTask,
  updateHint,
  createFile,
  createTaskSkill,
  createTechniqueTag,
  createTaskSkillTechniqueTag,
  createTechnologyTag,
  createTaskTechnologyTag,
  deleteTaskSkill,
  deleteTaskSkillTechniqueTag,
  deleteTaskTechnologyTag,
  createHistory,
  createTaskReviewer,
} from '../graphql/mutations';
import {
  getTask,
  listSpecialties,
  listTechniqueTags,
  listTechnologyTags,
  listLabPrototypes,
} from '../graphql/queries';
import { CHALLENGE_TYPE, PROFICIENCY, PROFICIENCY_LEVELS } from '../constants';
import { invalidateAdminHomePageDataQuery } from '../queries/invalidateQueries';
import { getProficiencyLevel } from '../shared/utils/difficultyMapping';
import { initialValues, validationSchema } from '../validation-schemas';
import TaskStepper from '../components/Task/TaskStepper';
import {
  createMultipleAttachments,
  createMultipleCompetencies,
  createMultipleHints,
  createMultipleSkills,
  createMultipleTechnologyTags,
  createTaskReviewers,
  generateHistory,
  removeMultipleSkills,
  removeMultipleTechnologyTags,
  updateExistingSkills,
  updateMultipleHints,
} from '../components/Task/utils';
import ReviewersModal from '../components/Task/ReviewersModal';
import { scrollToTop } from '../shared/utils/scroll';

const CreateTask = ({ formMode }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [cancel, setCancel] = useState(false);
  const [prevCompetencies, setPrevCompetencies] = useState([]);
  const [prevHints, setPrevHints] = useState([]);
  const [prevAttachments, setPrevAttachments] = useState([]);
  const [prevSkills, setPrevSkills] = useState([]);
  const [prevTechnologyTags, setPrevTechnologyTags] = useState([]);
  const [saveAction, setSaveAction] = useState(undefined);
  const [openReviewersModal, setOpenReviewersModal] = useState(false);
  const { orgId, userId, permissions } = useSnapshot(globalStore);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    formState: { errors, submitCount, isDirty, dirtyFields },
    reset,
    getValues,
    trigger,
  } = reactHookFormMethods;

  const params = useParams();
  const { taskId } = params;
  const isEditMode = formMode === 'edit';

  // List Labs which are with status READY
  const { data: labsData, loading: labsDataLoading } = useQueryRecursive(
    gql(listLabPrototypes),
    {
      variables: {
        filter: {
          orgId: { eq: orgId },
          status: { eq: 'READY' },
        },
      },
    },
  );

  const allLabs = labsData?.listLabPrototypes?.items || [];

  const labName = getValues('lab');
  const labId = labName
    ? allLabs?.filter((l) => l.name === labName)[0]?.id
    : null;

  const { loading: taskLoading } = useQueryRecursive(gql(getTask), {
    variables: {
      id: taskId,
    },
    staleTime: { seconds: 0 },
    skip: !isEditMode,
    onCompleted: (data) => {
      const {
        name,
        description,
        summary,
        difficulty,
        estimatedSolveTime,
        competencies: editCompetencies,
        hints: editHints,
        answer,
        solution,
        solutionVideo,
        solutionSteps,
        files,
        status,
        modulePartId,
        lab,
        skills,
        specialty,
        technologyTags,
        userId: creatorId,
        reviewers,
      } = data.getTask;

      if (isEditMode) {
        const canUserManageContent =
          Authorization.canManageContent(permissions);
        // if not creator and don't have manage-content perms then send them back to tasks page
        if (
          (!canUserManageContent && creatorId !== userId) ||
          status !== 'DRAFT'
        ) {
          singleSpa.navigateToUrl('/tasks');
          return;
        }
      }

      const selectedCompetencies =
        editCompetencies?.items?.map((item) => ({
          id: item.id,
          competencyId: item.competency.id,
          competency: item.competency.description,
          categoryId: item?.categoryId,
          workRoleId: item?.workRoleId,
          areaId: item?.areaId,
        })) || [];

      const uploadAttachments =
        files?.items
          ?.filter((item) => item?.type?.startsWith('application'))
          ?.map((file) => ({
            id: file.id,
            name: file.name,
            url: file.url,
            fileSize: file.size,
            fileType: file.type,
          })) || [];

      const hints =
        editHints?.items?.map((h) => ({
          id: h.id,
          description: h.text,
          releasedOnStart: h.automaticRelease,
        })) || [];

      const prevSkillsData =
        skills?.items?.map((skill) => ({
          dbId: skill.id,
          skillId: skill.skill.id,
          techniqueTags:
            skill?.techniqueTags?.items?.map((techniqueTag) => ({
              dbId: techniqueTag.id,
              techniqueId: techniqueTag.techniqueTag.id,
              name: techniqueTag.techniqueTag.name,
            })) || [],
        })) || [];

      const prevTechTags =
        technologyTags?.items?.map((technologyTag) => ({
          dbId: technologyTag.id,
          technologyId: technologyTag.technologyTag.id,
          name: technologyTag.technologyTag.name,
        })) || [];

      const prevSpecialtyData = {
        value: specialty?.name,
        optionData: {
          specialtyId: specialty?.id,
          name: specialty?.name,
          skills: specialty?.skills?.items?.map((skill) => ({
            skillId: skill.id,
            name: skill.name,
            description: skill.description,
          })),
        },
      };

      const taskDetails = {
        name,
        description,
        summary,
        difficulty: PROFICIENCY_LEVELS[difficulty],
        challengeType: (modulePartId || lab) && {
          id: 'challengeType',
          value: CHALLENGE_TYPE.LAB_BASED,
        },
        estimatedSolveTime,
        selectedCompetencies,
        uploadAttachments,
        uploadSolnAttachments:
          files?.items
            ?.filter((item) => item.type === 'text/markdown')
            ?.map((file) => ({
              id: file.id,
              name: file.name,
              url: file.url,
              fileSize: file.size,
              fileType: file.type,
            })) || [],
        hints,
        flag: answer,
        solnWalkthrough: solution,
        solnVideo: solutionVideo,
        solnSteps: solutionSteps,
        status,
        partId: modulePartId,
        specialty: prevSpecialtyData,
        skills: prevSkillsData,
        technologyTags: prevTechTags,
        lab: lab?.name,
        labInfo: lab,
        reviewers: reviewers?.items?.map((r) => ({
          id: r?.assigneeId,
          name: r?.assignee?.name,
          email: r?.assignee?.email,
          assigneeId: r?.assigneeId,
          assignedBy: r?.userId,
        })),
      };
      reset(taskDetails);

      setPrevCompetencies(selectedCompetencies);
      setPrevHints(hints);
      setPrevAttachments(uploadAttachments);
      setPrevSkills(prevSkillsData);
      setPrevTechnologyTags(prevTechTags);
    },
  });

  const { data: specialties } = useQueryRecursive(gql(listSpecialties));
  const { data: techniqueTagsData } = useQueryRecursive(gql(listTechniqueTags));
  const { data: technologyTagsData } = useQueryRecursive(
    gql(listTechnologyTags),
  );

  const [
    createCompetencyTaskMutation,
    { loading: createCompetencyTaskLoading },
  ] = useMutation(gql(createCompetencyTask));
  const [createHintMutation] = useMutation(gql(createHint));
  const [createFileMutation] = useMutation(gql(createFile));
  const [createTaskSkillMutation, { loading: createTaskSkillLoading }] =
    useMutation(gql(createTaskSkill));
  const [
    createTaskSkillTechniqueTagMutation,
    { loading: createTaskSkillTechniqueTagLoading },
  ] = useMutation(gql(createTaskSkillTechniqueTag));
  const [createTechniqueTagMutation, { loading: createTechniqueTagLoading }] =
    useMutation(gql(createTechniqueTag));
  const [createTechnologyTagMutation, { loading: createTechnologyTagLoading }] =
    useMutation(gql(createTechnologyTag));
  const [
    createTaskTechnologyTagMutation,
    { loading: createTaskTechnologyTagLoading },
  ] = useMutation(gql(createTaskTechnologyTag));

  const [deleteTaskSkillMutation, { loading: deleteTaskSkillLoading }] =
    useMutation(gql(deleteTaskSkill));
  const [
    deleteTaskSkillTechniqueTagMutation,
    { loading: deleteTaskSkillTechniqueTagLoading },
  ] = useMutation(gql(deleteTaskSkillTechniqueTag));
  const [
    deleteTaskTechnologyTagMutation,
    { loading: deleteTaskTechnologyLoading },
  ] = useMutation(gql(deleteTaskTechnologyTag));

  const [updateHintMutation] = useMutation(gql(updateHint));
  const [createHistoryMutation] = useMutation(gql(createHistory));

  // create Task
  const [createTaskMutation, { loading: createTaskLoading }] = useMutation(
    gql(createTask),
    {
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );

  // update Task
  const [updateTaskMutation, { loading: updateTaskLoading }] = useMutation(
    gql(updateTask),
    {
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors?.[0]?.message);
      },
    },
  );

  const [createTaskReviewerMutation] = useMutation(gql(createTaskReviewer), {
    onCompleted: (result) => {
      // create history for reviewer
      generateHistory({
        createHistoryMutation,
        action: 'ASSIGNED_REVIEW',
        taskId: result?.createTaskReviewer?.taskId,
        userId: result?.createTaskReviewer?.userId,
        assigneeId: result?.createTaskReviewer?.assigneeId,
      });
    },
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors?.[0]?.message);
    },
  });

  const SAVE_ACTIONS = {
    SAVE_DRAFT: 'DRAFT',
    SAVE_AND_SUBMIT: 'IN_REVIEW',
  };

  const onSubmit = async (status, data) => {
    const {
      name,
      description,
      summary,
      estimatedSolveTime,
      flag,
      solnWalkthrough,
      solnVideo,
      solnSteps,
      selectedCompetencies,
      hints,
      uploadAttachments,
      difficulty,
      specialty,
      skills,
      technologyTags,
    } = data;

    const newCompetencies = selectedCompetencies?.filter(
      (__competency) =>
        !prevCompetencies?.some(
          (prevCompetency) => prevCompetency.id === __competency.id,
        ),
    );

    const newAttachments = uploadAttachments?.filter(
      (attachment) =>
        !prevAttachments?.some(
          (prevAttachment) => prevAttachment.id === attachment.id,
        ),
    );

    const reviewersAdded = getValues('reviewers');

    if (isEditMode && !isDirty) {
      // update only task status if none of the field is dirty
      await updateTaskMutation({
        variables: {
          input: {
            id: taskId,
            status,
            labId: labName ? labId : null,
            type: labName ? 'LAB' : 'STATIC',
          },
        },
        onCompleted: () => {
          // create history for new/edit task
          generateHistory({
            createHistoryMutation,
            action: 'EDITED',
            taskId,
            userId,
          });

          // add reviewers if there are any
          createTaskReviewers(
            createTaskReviewerMutation,
            reviewersAdded,
            taskId,
            userId,
          );
        },
      });
      // check if competencies or attachments is not empty
      // NOTE: for some reason, changing competencies or
      // attachments does not trigger isDirty to true atm.
      if (
        selectedCompetencies.some(Boolean) ||
        uploadAttachments.some(Boolean) ||
        skills.some(Boolean) ||
        technologyTags.some(Boolean)
      ) {
        // update task with competencies or hints or attachments only
        // eslint-disable-next-line no-use-before-define
        addDetailsToTask({
          taskId,
          newCompetencies,
          newAttachments,
          skills,
          technologyTags,
          status,
        });

        return;
      }

      successToastMessage('Your challenge has been saved successfully');
      singleSpa.navigateToUrl('/tasks');

      return;
    }

    const taskMutation = isEditMode ? updateTaskMutation : createTaskMutation;
    const specialtyId = specialty?.optionData?.specialtyId;
    const createTaskParams = {
      name: name?.trim(),
      description: description?.trim(),
      summary: summary?.trim(),
      difficulty: difficulty ? getProficiencyLevel(difficulty) : undefined,
      recommendedPoints: PROFICIENCY[difficulty]?.points,
      estimatedSolveTime: estimatedSolveTime
        ? Number(estimatedSolveTime)
        : undefined,
      solution: solnWalkthrough?.trim(),
      solutionVideo: solnVideo || undefined,
      solutionSteps: solnSteps || undefined,
      answer: flag,
      status,
      type: labName ? 'LAB' : 'STATIC',
      labId,
      userId,
      orgId,
      specialtyId,
    };

    const mutatedDirtyFields = { ...dirtyFields };

    delete mutatedDirtyFields?.technologyTags;
    delete mutatedDirtyFields?.skills;
    delete mutatedDirtyFields?.selectedCompetencies;
    delete mutatedDirtyFields?.uploadAttachments;
    delete mutatedDirtyFields?.hints;
    delete mutatedDirtyFields?.partId;
    delete mutatedDirtyFields?.uploadSolnAttachments;
    delete mutatedDirtyFields?.reviewers;
    delete mutatedDirtyFields?.challengeType;
    if (!mutatedDirtyFields?.name) {
      delete mutatedDirtyFields?.name;
    }

    const updateTaskParams = Object.keys(mutatedDirtyFields)?.reduce(
      (input, dirtyField) => {
        switch (dirtyField) {
          case 'name':
          case 'description':
            return {
              ...input,
              [dirtyField]: data[dirtyField]?.trim(),
            };
          case 'summary':
            return {
              ...input,
              [dirtyField]: data[dirtyField]?.trim(),
            };
          case 'solnWalkthrough':
            return {
              ...input,
              solution: data[dirtyField]?.trim(),
            };
          case 'solnVideo':
            return {
              ...input,
              solutionVideo: data[dirtyField]?.trim(),
            };
          case 'solnSteps':
            return {
              ...input,
              solutionSteps: data[dirtyField]?.trim(),
            };
          case 'difficulty':
            return {
              ...input,
              difficulty: getProficiencyLevel(difficulty),
              recommendedPoints: PROFICIENCY[difficulty]?.points,
            };
          case 'estimatedSolveTime':
            return {
              ...input,
              [dirtyField]: Number(data[dirtyField]),
            };
          case 'flag':
            return {
              ...input,
              answer: flag,
            };
          case 'hint':
            return {
              ...input,
            };
          case 'specialty':
            return {
              ...input,
              specialtyId,
            };
          case 'lab':
          case 'labInfo':
            return {
              ...input,
            };
          default:
            return {
              ...input,
              [dirtyField]: data[dirtyField],
            };
        }
      },
      {},
    );

    await taskMutation({
      variables: {
        input: isEditMode
          ? {
              id: taskId,
              ...updateTaskParams,
              status,
              labId: labName ? labId : null,
              type: labName ? 'LAB' : 'STATIC',
            }
          : createTaskParams,
      },
      onCompleted: (result) => {
        // create history for new/edit task
        generateHistory({
          createHistoryMutation,
          action: isEditMode ? 'EDITED' : 'CREATED',
          taskId: isEditMode ? taskId : result.createTask.id,
          userId,
        });

        // add reviewers if there are any
        createTaskReviewers(
          createTaskReviewerMutation,
          reviewersAdded,
          isEditMode ? taskId : result.createTask.id,
          userId,
        );
        // add details
        // eslint-disable-next-line no-use-before-define
        addDetailsToTask({
          taskId: isEditMode ? taskId : result.createTask.id,
          newCompetencies,
          selectedHints: hints,
          prevHints,
          newAttachments,
          status,
          skills,
          technologyTags,
        });
      },
    });
  };

  const triggerAfterSubmit = (fields) => {
    if (submitCount > 0) {
      trigger(fields);
    }
  };

  const isLoading =
    taskLoading ||
    createTaskLoading ||
    updateTaskLoading ||
    createCompetencyTaskLoading ||
    createTechniqueTagLoading ||
    createTaskSkillLoading ||
    createTaskSkillTechniqueTagLoading ||
    createTechnologyTagLoading ||
    createTaskTechnologyTagLoading ||
    deleteTaskSkillLoading ||
    deleteTaskSkillTechniqueTagLoading ||
    deleteTaskTechnologyLoading;

  const technologyTags = _.uniqBy(
    technologyTagsData?.listTechnologyTags?.items.map((t) => ({
      ...t,
      technologyId: t.id,
    })) || [],
    (item) => item.name.toLowerCase(),
  );

  const techniqueTags = _.uniqBy(
    techniqueTagsData?.listTechniqueTags?.items.map((t) => ({
      ...t,
      techniqueId: t.id,
    })) || [],
    (item) => item.name.toLowerCase(),
  );

  const addDetailsToTask = ({
    // eslint-disable-next-line no-shadow
    taskId,
    newCompetencies = [],
    selectedHints = [],
    // eslint-disable-next-line no-shadow
    prevHints = [],
    newAttachments = [],
    status,
    skills = [],
    // eslint-disable-next-line no-shadow
    technologyTags = [],
  }) => {
    if (skills?.length > 0) {
      const newSkills = skills.filter(
        (skill) =>
          !prevSkills.some((prevSkill) => prevSkill.skillId === skill.skillId),
      );

      const deletingSkills = prevSkills.filter(
        (prevSkill) =>
          !skills.some((skill) => skill.skillId === prevSkill.skillId),
      );

      const existingSkills = skills.filter((skill) =>
        prevSkills.some((prevSkill) => prevSkill.skillId === skill.skillId),
      );

      if (existingSkills?.length > 0) {
        updateExistingSkills(
          deleteTaskSkillTechniqueTagMutation,
          prevSkills,
          techniqueTags,
          createTechniqueTagMutation,
          userId,
          createTaskSkillTechniqueTagMutation,
          existingSkills,
        );
      }

      if (newSkills?.length > 0) {
        createMultipleSkills(
          createTaskSkillMutation,
          techniqueTags,
          createTechniqueTagMutation,
          createTaskSkillTechniqueTagMutation,
          userId,
          taskId,
          newSkills,
        );
      }

      if (deletingSkills?.length > 0) {
        removeMultipleSkills(
          deleteTaskSkillMutation,
          deleteTaskSkillTechniqueTagMutation,
          deletingSkills,
        );
      }
    }

    if (technologyTags?.length > 0) {
      const newTechnologies = technologyTags.filter(
        (techTag) =>
          !prevTechnologyTags.some(
            (prevTechTag) => techTag.technologyId === prevTechTag.technologyId,
          ),
      );

      const deletingTechnologies = prevTechnologyTags.filter(
        (prevTechnologyTag) =>
          !technologyTags.some(
            (techTag) =>
              techTag.technologyId === prevTechnologyTag.technologyId,
          ),
      );

      if (newTechnologies?.length > 0) {
        createMultipleTechnologyTags(
          createTechnologyTagMutation,
          createTaskTechnologyTagMutation,
          userId,
          taskId,
          newTechnologies,
        );
      }

      if (deletingTechnologies?.length > 0) {
        removeMultipleTechnologyTags(
          deleteTaskTechnologyTagMutation,
          deletingTechnologies,
        );
      }
    }

    if (selectedHints?.length > 0) {
      // creat new hints if not exist
      const newHints = selectedHints?.filter(
        (hint) => !prevHints.some((item) => item.id === hint.id),
      );

      createMultipleHints(createHintMutation, taskId, newHints);

      // update hints if existing hint is updated with the new description
      const hintsToUpdate = selectedHints?.filter((hint) =>
        prevHints.some(
          (item) =>
            item.id === hint.id && item.description !== hint.description,
        ),
      );

      if (hintsToUpdate?.length > 0) {
        updateMultipleHints(updateHintMutation, taskId, hintsToUpdate);
      }
    }

    if (newCompetencies?.length > 0) {
      createMultipleCompetencies(
        createCompetencyTaskMutation,
        taskId,
        newCompetencies,
      );
    }

    if (newAttachments?.length > 0) {
      const uploadAttachments = newAttachments?.filter((item) =>
        item.fileType.startsWith('application'),
      );

      createMultipleAttachments(
        createFileMutation,
        amplifyConfig,
        taskId,
        uploadAttachments,
      );
    }

    successToastMessage(
      status === 'DRAFT'
        ? 'Your challenge has been saved as a Draft'
        : 'Reviewer(s) assigned for the challenge. Challenge is now in-review.',
    );
    reset();
    invalidateAdminHomePageDataQuery();
    singleSpa.navigateToUrl('/tasks');
  };
  const getPageMandatoryFields = (page, errorKeys) => {
    const mandatoryFieldsMap = {
      1: {
        [SAVE_ACTIONS.SAVE_DRAFT]: ['name', 'difficulty'],
        [SAVE_ACTIONS.SAVE_AND_SUBMIT]: [
          'name',
          'difficulty',
          'description',
          'summary',
          'estimatedSolveTime',
          'flag',
          'solnWalkthrough',
        ],
      },
      2: {
        [SAVE_ACTIONS.SAVE_DRAFT]: [],
        [SAVE_ACTIONS.SAVE_AND_SUBMIT]: [
          'specialty',
          'skills',
          'technologyTags',
        ],
      },
    };
    const fields = mandatoryFieldsMap[page][saveAction] || [];
    return fields.some((e) => errorKeys.includes(e));
  };
  const steps = ['Details and Resources', 'Mapping and Support Info'];
  const allErrors = Object.keys(errors);
  const pageErrors = steps?.map((__, i) =>
    getPageMandatoryFields(i + 1, allErrors),
  );

  return (
    <BasePage
      heading={
        formMode === 'edit' ? (
          <FdSkeleton height={40} loading={taskLoading}>
            <Box className="flex items-center">
              <span>{`Edit ${getValues('name')}`}</span>
              <FdChip label="Draft" size="small" className="ml-5" />
            </Box>
          </FdSkeleton>
        ) : (
          'Create Challenge'
        )
      }
    >
      <FormProvider {...reactHookFormMethods}>
        <form>
          <Box>
            <TaskStepper
              steps={steps}
              activeStep={activeStep}
              errors={pageErrors}
            />
            <FdSkeleton loading={isLoading} height={1220}>
              {
                {
                  0: (
                    <Box className="flex flex-col gap-y-3">
                      <Details />
                      <Flag />
                      {/* {taskLoading || labsDataLoading ? (
                        <FdProgress />
                      ) : (
                        <Lab
                          allLabs={allLabs}
                          labInfo={getValues('labInfo')}
                          isEdit
                          isDraft
                        />
                      )} */}
                      <Attachments />
                      <Solution
                        preHeader={
                          errors.solnWalkthrough && (
                            <Box mb={2}>
                              <FdAlert
                                alertTitle="Solution is required"
                                variant="error"
                                message="Please provide a solution walk-through in the markdown editor below. "
                              />
                            </Box>
                          )
                        }
                        triggerAfterSubmit={triggerAfterSubmit}
                      />
                    </Box>
                  ),
                  1: (
                    <Box className="flex flex-col gap-y-3">
                      <Competencies
                        specialties={specialties?.listSpecialties?.items}
                        techniqueTagsData={techniqueTags}
                        technologyTagsData={technologyTags}
                      />
                      <Hints />
                      <SolutionWalkthrough />
                    </Box>
                  ),
                }[activeStep]
              }
            </FdSkeleton>
          </Box>
          <ReviewersModal
            openModal={openReviewersModal}
            setOpenModal={setOpenReviewersModal}
            onReviewersSelection={() => {
              setTimeout(() => {
                onSubmit(SAVE_ACTIONS.SAVE_AND_SUBMIT, getValues());
              }, 500);
            }}
            mode="send-for-review"
          />
          <BasePageButtonContainer>
            <Box mt={1}>
              <FdButton
                size="large"
                variant="tertiary"
                onClick={() => setCancel(true)}
                disabled={isLoading}
              >
                Cancel
              </FdButton>
              <FdButton
                size="large"
                variant="secondary"
                onClick={() => setActiveStep(activeStep === 0 ? 1 : 0)}
                disabled={isLoading}
              >
                {activeStep === 0 ? 'Next' : 'Back'}
              </FdButton>
              {activeStep === 1 && (
                <>
                  <FdTooltip title="Submit challenge for mandatory review and select reviewer(s).">
                    <span>
                      <FdButton
                        size="large"
                        variant="primary"
                        onClick={async () => {
                          setSaveAction(SAVE_ACTIONS.SAVE_AND_SUBMIT);
                          const res = await trigger();
                          if (res) {
                            setOpenReviewersModal(true);
                          } else {
                            scrollToTop();
                          }
                        }}
                        disabled={isLoading}
                      >
                        Submit for Review
                      </FdButton>
                    </span>
                  </FdTooltip>
                  <FdTooltip title="Save as draft for later editing.">
                    <span>
                      <FdButton
                        size="large"
                        variant="primary"
                        onClick={async () => {
                          setSaveAction(SAVE_ACTIONS.SAVE_DRAFT);
                          const res = await trigger(['name', 'difficulty']);
                          if (res) {
                            onSubmit(SAVE_ACTIONS.SAVE_DRAFT, getValues());
                          } else {
                            scrollToTop();
                          }
                        }}
                        disabled={isLoading}
                      >
                        Save Draft
                      </FdButton>
                    </span>
                  </FdTooltip>
                </>
              )}
            </Box>
          </BasePageButtonContainer>
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Are you sure you want to leave this page? Any changes you have made will be lost."
            confirm="Stay"
            dismiss="Leave"
            open={cancel}
            onConfirm={() => {
              setCancel(false);
            }}
            onDismiss={() => {
              setCancel(false);
              warningToastMessage(
                'Changes to your challenge have not been saved',
              );
              singleSpa.navigateToUrl('/tasks');
            }}
            setOpen={setCancel}
          />
        </form>
      </FormProvider>
    </BasePage>
  );
};

CreateTask.propTypes = {
  formMode: PropTypes.oneOf(['create', 'edit']).isRequired,
};
export default CreateTask;
