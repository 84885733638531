import React, { useEffect } from 'react';
import { StylesProvider, createGenerateClassName } from '@material-ui/core';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import {
  setShowNav,
  AppBasePageProvider,
  ReactQueryProvider,
} from '@fifthdomain/fe-shared';
import { AuthProvider } from '@fifthdomain/auth';
import { ToastContainer } from 'react-toastify';
import GraphQlClient from './shared/GraphQlClient';
import ListTasks from './pages/ListTasks';
import CreateTask from './pages/CreateTask';
import ViewTask from './pages/ViewTask';
import ProtectedRoute from './routes/ProtectedRoute';
import AppThemeProvider from './providers/AppThemeProvider';
import ReviewTask from './pages/ReviewTask';

const generateClassName = createGenerateClassName({
  productionPrefix: 'tasks',
  seed: 'tasks',
});

const App = () => {
  useEffect(() => {
    setShowNav(true);
  });

  return (
    <GraphQlClient>
      <AuthProvider>
        <ReactQueryProvider>
          <AppThemeProvider>
            <BrowserRouter>
              <StylesProvider generateClassName={generateClassName}>
                <ToastContainer />
                <AppBasePageProvider>
                  <BrowserRouter>
                    <Switch>
                      <ProtectedRoute
                        path="/tasks"
                        component={ListTasks}
                        exact
                      />
                      <ProtectedRoute
                        path="/tasks/create"
                        component={CreateTask}
                        formMode="create"
                        exact
                      />
                      <ProtectedRoute
                        path="/tasks/edit-draft/:taskId"
                        component={CreateTask}
                        formMode="edit"
                        exact
                      />
                      <ProtectedRoute
                        path="/tasks/view/:taskId"
                        component={ViewTask}
                        exact
                      />
                      <ProtectedRoute
                        path="/tasks/:mode/:taskId"
                        component={ReviewTask}
                        exact
                      />
                      <Redirect to="/" />
                    </Switch>
                  </BrowserRouter>
                </AppBasePageProvider>
              </StylesProvider>
            </BrowserRouter>
          </AppThemeProvider>
        </ReactQueryProvider>
      </AuthProvider>
    </GraphQlClient>
  );
};

export default App;
