import _ from 'lodash';

export const TASK_CATEGORY = {
  STEGANOGRAPHY: {
    color: '#F06292',
    secondaryColor: '#F8BBD0',
    label: 'Steganography',
  },
  PHYSICAL: {
    color: '#2196F3',
    secondaryColor: '#90CAF9',
    label: 'Physical',
  },
  HARDWARE: {
    color: '#81D4FA',
    secondaryColor: '#E1F5FE',
    label: 'Hardware',
  },
  ICSSCADA: {
    color: '#00BCD4',
    secondaryColor: '#B2EBF2',
    label: 'ICS/SCADA',
  },
  IOT: {
    color: '#26A69A',
    secondaryColor: '#B2DFDB',
    label: 'IOT',
  },
  WIRELESS: {
    color: '#2E7D32',
    secondaryColor: '#4CAF50',
    label: 'Wireless',
  },
  TRIVIA: {
    color: '#8BC34A',
    secondaryColor: '#C5E1A5',
    label: 'Trivia',
  },
  DFIR: {
    color: '#AB47BC',
    secondaryColor: '#CE93D8',
    label: 'DFIR',
  },
  FORENSICS: {
    color: '#F06292',
    secondaryColor: '#F8BBD0',
    label: 'Forenstics',
  },
  MISC: {
    color: '#D4E157',
    secondaryColor: '#F0F4C3',
    label: 'Miscellaneous',
  },
  REVENG: {
    color: '#FB8C00',
    secondaryColor: '#FFCC80',
    label: 'Reverse Engineering',
  },
  EXPLOITATION: {
    color: '#5D4037',
    secondaryColor: '#A1887F',
    label: 'Explotaion',
  },
  SCRIPTING: {
    color: '#FF5722',
    secondaryColor: '#FF8A65',
    label: 'Scripting',
  },
  PWN: {
    color: '#00897B',
    secondaryColor: '#80CBC4',
    label: 'Pwn',
  },
  BOOTTOROOT: {
    color: '#FDD835',
    secondaryColor: '#FFF9C4',
    label: 'Boot2Root',
  },
  ANALYST: {
    color: '#512DA8',
    secondaryColor: '#9575CD',
    label: 'Analyst',
  },
  ANALYSIS: {
    color: '#512DA8',
    secondaryColor: '#9575CD',
    label: 'Analyst',
  },
  CRYPTOGRAPHY: {
    color: '#FFB300',
    secondaryColor: '#FFECB3',
    label: 'Cryptography',
  },
  OSINT: {
    color: '#5C6BC0',
    secondaryColor: '#9FA8DA',
    label: 'OSINT',
  },
  BADGE: {
    color: '#00BCD4',
    secondaryColor: '#B2EBF2',
    label: 'Badge',
  },
};

export const TASK_TYPE_COLOR = {
  Lab: 'rgba(107, 107, 242, 1)',
  Container: '#63A4FF',
  Static: 'rgba(158, 173, 255, 1)',
};

export const getReleasedTemplatesChallenges = (releasedTemplates) => {
  const challenges = releasedTemplates
    ?.map((template) => template.tasks.items?.map((task) => task.task))
    .flat();
  const uniqueChallenges = _.uniqBy(challenges, 'id');
  return uniqueChallenges;
};
