import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stepper, Step, StepLabel } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';

const TaskStepper = ({ steps, activeStep, errors }) => {
  return (
    <Box width="510px">
      <Stepper
        activeStep={activeStep}
        style={{ background: 'none', marginLeft: '-30px' }}
      >
        {steps?.map((label, i) => {
          const isError = errors?.[i];
          return (
            <Step key={label} completed={false}>
              <StepLabel
                optional={
                  <FdTypography variant="captiontext1" color="error">
                    {isError ? 'Incomplete field(s)' : ''}
                  </FdTypography>
                }
                error={isError}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

TaskStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeStep: PropTypes.string.isRequired,
};

export default TaskStepper;
