export const scrollToTop = () => window.scrollTo({ top: 0 });

export const scrollToBottom = () => {
  const scrollHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight,
  );

  window.scrollTo({ top: scrollHeight });
};
