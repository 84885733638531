import React from 'react';
import { format } from 'date-fns';
import { Box, IconButton } from '@material-ui/core';
import {
  FdChip as Chip,
  toProperCase,
  FdTypography,
  FdTooltip,
  FdIcons,
  FdChips,
} from '@fifthdomain/fe-shared';
import ChallengeInfoCell from '../Task/ChallengeInfoCell';
import { getTagColor } from '../../shared/utils/tagUtils';
import { sortByDateField } from '../../shared/utils/dateUtils';
import TableHeaderColumnWithTooltip from './TableHeaderColumnWithTooltip';
import {
  getCommaSeparated,
  getCommaSeparatedPlusSuffix,
  upperCaseFirstLetter,
} from '../../shared/utils/stringUtils';

export const challengeInfo = {
  field: 'challengeInfo',
  width: 320,
  headerName: 'Challenge Name',
  valueGetter: (params) => params.row.name,
  renderCell: (params) => <ChallengeInfoCell values={params.row} />,
};

export const name = {
  field: 'name',
  width: 180,
  headerName: 'Challenge Name',
  hide: true,
};

export const status = {
  field: 'status',
  headerName: 'Status',
  width: 140,
  valueGetter: (params) => params?.value?.label,
  renderCell: (params) => (
    <Chip
      color={params.row?.status?.variant}
      size="small"
      label={toProperCase(params.value, 'lowerCase')}
    />
  ),
  sortComparator: (v1, v2, param1, param2) =>
    param1.value.localeCompare(param2.value),
};

export const ownershipStatus = {
  field: 'owned',
  headerName: 'Ownership status',
  width: 140,
  valueGetter: (params) => (params.value ? 'Owned' : 'Leased'),
  renderCell: (params) => (
    <Chip
      color={params.row?.owned ? 'success' : 'default'}
      size="small"
      label={params.row?.owned ? 'Owned' : 'Leased'}
    />
  ),
  sortComparator: (v1, v2, param1, param2) =>
    param1.value.localeCompare(param2.value),
};

export const specialtyName = {
  field: 'specialtyName',
  width: 120,
  headerName: 'Professional Specialty',
  renderHeader: () => (
    <TableHeaderColumnWithTooltip
      title="Professional Specialty"
      tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge or task can be classified."
    />
  ),
};

export const creator = {
  field: 'creator',
  width: 220,
  headerName: 'Creator/ Provider',
  renderHeader: () => {
    const { InfoOutlined } = FdIcons;
    return (
      <Box display="flex" alignItems="center">
        <FdTypography variant="subtitle2">Creator/ Provider</FdTypography>
        <FdTooltip
          title={
            <Box>
              Creator of the challenge is the manager/organisation which created
              that challenge. Creator information appears for challenges that
              are owned by your organisation.
              <br />
              <br />
              Provider name appears for challenges that have been leased from,
              (or provided by) other organisation.
            </Box>
          }
        >
          <IconButton size="small" style={{ marginLeft: '10px' }}>
            <InfoOutlined />
          </IconButton>
        </FdTooltip>
      </Box>
    );
  },
  valueGetter: (params) => {
    const rowVal = params?.row;
    if (rowVal?.owned) {
      return rowVal?.creatorOrgId === rowVal?.ownerOrgId
        ? `Creator: ${rowVal.creator}`
        : `Creator: ${rowVal?.creatorOrg}`;
    }
    return `Provider: ${rowVal?.ownerOrg}`;
  },
};
export const creatorName = {
  field: 'creatorName',
  width: 220,
  headerName: 'Creator',
};
export const createdDate = {
  field: 'createdDate',
  width: 120,
  headerName: 'Created date',
  valueFormatter: (params) => format(new Date(params?.value), 'dd/MM/yyyy'),
};

export const reviewerList = {
  field: 'reviewers',
  width: 220,
  headerName: 'Reviewers',
  valueGetter: (params) => getCommaSeparated(params.value?.map((r) => r?.name)),
  renderCell: (params) =>
    getCommaSeparatedPlusSuffix(params.row.reviewers?.map((r) => r?.name)),
};
export const rejectedDate = {
  field: 'rejectedDate',
  width: 120,
  headerName: 'Rejected Date',
  valueFormatter: (params) => format(new Date(params?.value), 'dd/MM/yyyy'),
};

export const skills = {
  field: 'skills',
  flex: 1,
  headerName: 'Skills',
  valueGetter: (params) => getCommaSeparated(params.value),
  renderHeader: () => (
    <TableHeaderColumnWithTooltip
      title="Skills"
      tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
    />
  ),
  renderCell: (params) => getCommaSeparatedPlusSuffix(params.row.skills),
};
export const owner = { field: 'owner', width: 180, headerName: 'Owner' };
export const type = {
  field: 'type',
  width: 80,
  headerName: 'Type', //  (Lab, Static)
  renderCell: (params) => upperCaseFirstLetter(params.value),
};
export const points = {
  field: 'points',
  width: 100,
  headerName: 'Points', //  (Lab, Static)
};
export const solves = {
  field: 'solves',
  width: 80,
  headerName: 'Solves',
  hide: true, // Hide solves for now
};
export const rentedTo = {
  field: 'rentedTo',
  width: 150,
  headerName: 'Rented to',
};
export const rentedFrom = {
  field: 'rentedFrom',
  width: 150,
  headerName: 'Rented from',
};
export const editRequestFrom = {
  field: 'editRequestFrom',
  width: 150,
  headerName: 'Edit request from',
};
export const editRequestTo = {
  field: 'editRequestTo',
  width: 150,
  headerName: 'Edit request to',
};
export const transactionDate = {
  field: 'transactionDate',
  width: 150,
  headerName: 'Transaction Day',
};
export const assignedTags = {
  field: 'assignedTags',
  headerName: 'Assigned Tags',
  width: 180,
  valueGetter: (params) =>
    params.row?.assignedTags.length > 0
      ? [...params.row?.assignedTags].map((t) => t.name)?.join(' ')
      : '',
  renderCell: (params) => {
    const _data = params.row?.assignedTags
      ?.sort(sortByDateField('updatedAt', 'desc'))
      .map((t) => ({
        label: t?.name,
        color: getTagColor(t?.color),
      }));
    return <FdChips data={_data} numberOfChipsDisplayed={1} caption="More" />;
  },
};
export const techniqueTags = {
  field: 'techniqueTags',
  headerName: 'Techniques',
  renderHeader: () => (
    <TableHeaderColumnWithTooltip
      title="Techniques"
      tooltipText="Techniques refer to the specific methods or strategies required to resolve a challenge."
    />
  ),
  width: 180,
  valueGetter: (params) =>
    params.row?.techniqueTags.length > 0
      ? [...params.row?.techniqueTags].map((t) => t?.label)?.join(' ')
      : '',
  renderCell: (params) => {
    const _data =
      params.row?.techniqueTags?.map((t) => ({
        label: t?.label,
        color: '',
      })) || [];
    return <FdChips data={_data} numberOfChipsDisplayed={1} />;
  },
};
export const technologyTags = {
  field: 'technologyTags',
  headerName: 'Technologies',
  renderHeader: () => (
    <TableHeaderColumnWithTooltip
      title="Technologies"
      tooltipText="Technologies include the environments (e.g., Windows, Linux, Docker) and tools (e.g., Splunk, Nessus, Python) incorporated within the challenge, crucial for its resolution."
    />
  ),
  width: 180,
  valueGetter: (params) =>
    params.row?.technologyTags.length > 0
      ? [...params.row?.technologyTags].map((t) => t?.label)?.join(' ')
      : '',
  renderCell: (params) => {
    const _data =
      params.row?.technologyTags?.map((t) => ({
        label: t?.label,
        color: '',
      })) || [];
    return <FdChips data={_data} numberOfChipsDisplayed={1} />;
  },
};

export const proficiency = {
  field: 'difficultyLabel',
  width: 100,
  headerName: 'Proficiency',
};
