import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';
import { Storage } from 'aws-amplify';

const useStyles = makeStyles(() => ({
  urlLink: {
    '& a': {
      color: '#1976D2 !important',
    },
  },
}));

const getFileSignedURL = (key, id) => {
  Storage.get(key).then((value) => {
    const attachmentLink = document.getElementById(id);
    attachmentLink.href = value;
  });
};

function FileAttachment({ id, name, url }) {
  const classes = useStyles();
  getFileSignedURL(name, id);
  return (
    <Box display="flex" alignItems="center">
      <Box mt={0.5} />
      <FdTypography variant="body1" className={classes.urlLink}>
        <a id={id} key={id} href={url}>
          {name}
        </a>
      </FdTypography>
    </Box>
  );
}

FileAttachment.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default FileAttachment;
