import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import {
  FdTypography,
  FdModal,
  FdLoadingSpinner,
  FdDelayed,
  FdAutocomplete,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  Authorization,
} from '@fifthdomain/fe-shared';
import { warningToastMessage } from '../../shared/utils/toast';
import { listAffliationRequestsByOrgId } from '../../graphql/queries';

const ReviewersModal = ({
  editMode,
  openModal,
  setOpenModal,
  onReviewersSelection,
}) => {
  const {
    control,
    setValue,
    getValues,
    trigger,
    resetField,
    formState: { errors },
  } = useFormContext();
  const globalSnap = useSnapshot(globalStore);
  const canUserManageContent = Authorization.canManageContent(
    globalSnap?.permissions,
  );

  const { data: listOrgUsersData, loading: listOrgUsersLoading } =
    useQueryRecursive(gql(listAffliationRequestsByOrgId), {
      variables: {
        limit: 500,
        orgId: globalSnap?.orgId,
      },
      staleTime: { minutes: 0 },
    });

  const listOrgUsers =
    listOrgUsersData?.listAffliationRequestsByOrgId?.items?.filter(
      (a) =>
        a.status === 'ACCEPTED' &&
        ['REVIEW_CONTENT', 'MANAGE_CONTENT'].some((p) =>
          a.user?.permissions?.includes(p),
        ),
    ) || [];

  // if user is not having manage-content permission then filter them out from reviewers
  const listOrgUsersFiltered = canUserManageContent
    ? listOrgUsers
    : listOrgUsers?.filter((u) => u?.userId !== globalSnap?.userId);

  const usersList =
    listOrgUsersFiltered?.map((u) => ({
      id: u?.userId,
      name: u?.user?.name,
      email: u?.email,
    })) || [];
  const currentReviewers =
    getValues('reviewers')?.map((r) => r?.assigneeId) || [];
  const usersListForDisplay = editMode
    ? usersList?.filter((u) => !currentReviewers?.includes(u?.id))
    : usersList;

  return (
    <Box>
      <FdModal
        title="Assign a reviewer to quality assure this challenge."
        confirm="Assign reviewer(s)"
        dismiss="Cancel"
        open={openModal}
        onConfirm={() => {
          onReviewersSelection(
            getValues('reviewers')?.filter((r) => !r?.assigneeId),
          );
          setOpenModal(false);
        }}
        onDismiss={() => {
          resetField(
            'reviewers',
            getValues('reviewers')?.filter((r) => r?.assigneeId),
          );
          setOpenModal(false);
          warningToastMessage('Reviewer(s) not assigned for the challenge.');
        }}
        setOpen={setOpenModal}
        disableConfirm={
          (editMode
            ? getValues('reviewers')?.filter((r) => !r?.pkId)?.length === 0
            : getValues('reviewers')?.length === 0) || errors?.reviewers
        }
      >
        <Box>
          <FdTypography variant="body2" color="secondary">
            The appointed reviewer(s) will evaluate and authorise the challenge,
            guaranteeing its preparedness to be published for use in events.
          </FdTypography>
          <Box my={1}>
            <FdTypography variant="body2" color="secondary">
              You can select up to 5 reviewers at one time for a challenge.
            </FdTypography>
          </Box>
          <Box>
            {listOrgUsersLoading ? (
              <FdLoadingSpinner />
            ) : (
              <Controller
                control={control}
                name="reviewers"
                render={({
                  field: { value: fieldValue },
                  fieldState: { error },
                }) => (
                  <FdDelayed delay={0}>
                    <Box className="flex flex-col gap-y-1 my-1">
                      <FdTypography variant="subtitle1">
                        Select reviewer(s) for this challenge.
                      </FdTypography>
                      <FdTypography variant="captiontext1" color="secondary">
                        Below is the list of all the affiliated users in this
                        organisation that have “Review Content” permission.
                      </FdTypography>
                    </Box>
                    <FdAutocomplete
                      label=""
                      options={usersListForDisplay}
                      multiple
                      fullWidth
                      selectAllLabel="Select all"
                      optionLabel="name"
                      optionValue="id"
                      defaultSelected={[]}
                      ListItemTextRenderer={({ option }) => {
                        return (
                          <Box className="flex justify-between items-center w-full">
                            <Box className="flex flex-col">
                              <FdTypography variant="body1">
                                {option?.name}
                              </FdTypography>
                              <FdTypography variant="caption">
                                {option?.email}
                              </FdTypography>
                            </Box>
                          </Box>
                        );
                      }}
                      onChange={async (values) => {
                        setValue(
                          'reviewers',
                          _.uniqBy(
                            [
                              ...fieldValue?.filter((v) => v?.assigneeId),
                              ...values,
                            ],
                            'id',
                          ),
                        );
                        await trigger('reviewers');
                      }}
                      error={error}
                      helperText={error && error.message}
                    />
                  </FdDelayed>
                )}
              />
            )}
          </Box>
        </Box>
      </FdModal>
    </Box>
  );
};

ReviewersModal.propTypes = {
  editMode: PropTypes.bool,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onReviewersSelection: PropTypes.func.isRequired,
};

ReviewersModal.defaultProps = {
  editMode: false,
};
export default ReviewersModal;
