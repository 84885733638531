import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdButton } from '@fifthdomain/fe-shared';

const FdFileUpload = ({
  onDrop,
  inProgress,
  fileTypes,
  fileStatus,
  fileCounts,
  buttonVariant,
  buttonText,
}) => {
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    accept: fileTypes,
  });

  const newFileStatus = () => {
    fileStatus(true);
  };

  return (
    <Box>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <FdButton
          variant={buttonVariant}
          onClick={fileCounts < 1 ? open : newFileStatus}
          data-cy="upload-btn"
          disabled={inProgress}
          aria-label="add-artifact"
          size="small"
        >
          {buttonText}
        </FdButton>
      </Box>
      <Box display="flex" justifyContent="center" mt={2} mb={2} width="100%">
        {inProgress && <CircularProgress />}
      </Box>
    </Box>
  );
};

FdFileUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  fileTypes: PropTypes.string,
  fileStatus: PropTypes.bool,
  fileCounts: PropTypes.number,
  buttonVariant: PropTypes.string,
  buttonText: PropTypes.string,
};

FdFileUpload.defaultProps = {
  inProgress: false,
  fileTypes: '',
  fileStatus: false,
  fileCounts: 0,
  buttonVariant: 'secondary',
  buttonText: 'Browse',
};

export default FdFileUpload;
