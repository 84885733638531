import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import {
  FdTable,
  FdModal,
  FdTextField,
  FdTypography,
  FdCheckbox,
  FdAlert,
} from '@fifthdomain/fe-shared';
import { warningToastMessage } from '../../shared/utils/toast';

const TransferTaskOwnership = ({
  rows,
  columns,
  onConfirm,
  onDismiss,
  transferTaskOwnershipModal,
  orgAlias,
  onChange,
  loading,
  error,
  errorMessage,
  setErrorMessage,
}) => {
  const [checked, setChecked] = useState(false);

  const labBasedTasks = rows?.filter(
    (task) => task?.type === 'LAB' && task?.labId,
  );

  // multiple tasks can include same lab, so only get the unique ones
  const uniqueLabBasedTasks = [
    ...new Set(labBasedTasks.map((task) => task.labId)),
  ].map((labId) => {
    return labBasedTasks.find((task) => task.labId === labId);
  });

  return (
    <FdModal
      title="Transfer Content"
      description=""
      confirm={loading ? 'Loading...' : 'Confirm Transfer'}
      disableConfirm={rows.length === 0 || loading}
      dismiss="Cancel"
      showDismiss
      open={transferTaskOwnershipModal}
      onConfirm={() => {
        if (!orgAlias) {
          setErrorMessage('Org Alias required!');
        } else if (!checked) {
          warningToastMessage(
            'Please agree to the terms to transfer ownership.',
          );
        } else {
          onConfirm();
          setChecked(false);
        }
      }}
      onDismiss={onDismiss}
      maxWidth="md"
    >
      <Box>
        <Box mb={2}>
          <FdTextField
            width="276px"
            id="org_alias"
            label="Enter Org Alias"
            placeholder=""
            required
            onChange={onChange}
            error={error}
            helperText={errorMessage}
          />
        </Box>
        <Box mb={2}>
          <FdAlert
            alertTitle="Note"
            variant="info"
            message="Only the content that is owned by your organisation can be transferred."
          />
        </Box>
        {rows.length > 0 && (
          <FdTable
            autoHeight
            title=""
            toolbarSettings={{
              hide: true,
            }}
            rows={rows}
            columns={columns}
            tablePageSize={5}
            gridId="tasks-transfer-ownerships"
          />
        )}
        {uniqueLabBasedTasks?.length > 0 && (
          <Box mt={2}>
            <Box mb={1}>
              <FdTypography variant="subtitle1">
                Following Labs and VMs are associated with challenges selected
                to be transferred:
              </FdTypography>
              <FdTypography variant="captiontext1" style={{ color: '#1565C0' }}>
                Please ensure to transfer ownership of the dependent assets as
                per the requirements for ownership transfer.
              </FdTypography>
            </Box>
            <FdTable
              rows={uniqueLabBasedTasks?.map((labBasedTask) => ({
                id: labBasedTask.labId,
                name: labBasedTask.lab.name,
                vms: labBasedTask.lab.vms.items.length,
              }))}
              columns={[
                {
                  field: 'name',
                  width: 200,
                  headerName: 'Lab Name',
                },
                {
                  field: 'vms',
                  width: 200,
                  headerName: 'No of VMS associated',
                },
              ]}
              title=""
              toolbarSettings={{
                hide: true,
              }}
              tablePageSize={5}
              autoHeight
            />
          </Box>
        )}
        {orgAlias && rows.length > 0 && (
          <Box mt={2}>
            <FdTypography variant="h3">{`Confirm Transfer of ${rows.length} challenges to ${orgAlias} `}</FdTypography>
            <FdTypography variant="body2">
              When you agree to transfer content to an org, you will no longer
              be the owner of the content. After the transaction is confirmed,
              you will lose access to this content.
            </FdTypography>

            <Box mt={2} mb={2}>
              <FdCheckbox
                label={`I agree to transfer content to ${orgAlias}`}
                checked={checked}
                onChange={(e) => setChecked(e)}
              />
            </Box>
          </Box>
        )}
      </Box>
    </FdModal>
  );
};

TransferTaskOwnership.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  transferTaskOwnershipModal: PropTypes.bool.isRequired,
  orgAlias: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.string.isRequired,
};

export default TransferTaskOwnership;
