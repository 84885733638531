import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import { FdTypography, FdIcons, FdTooltip } from '@fifthdomain/fe-shared';

const TableHeaderColumnWithTooltip = ({ title, tooltipText }) => {
  const { InfoOutlined } = FdIcons;
  return (
    <Box display="flex" alignItems="center">
      <FdTypography variant="subtitle2">{title}</FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton size="small" style={{ marginLeft: '10px' }}>
          <InfoOutlined />
        </IconButton>
      </FdTooltip>
    </Box>
  );
};

TableHeaderColumnWithTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  tooltipText: PropTypes.node.isRequired,
};

export default TableHeaderColumnWithTooltip;
