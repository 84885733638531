import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import singleSpa from 'single-spa';
import _ from 'lodash';
import {
  FdTab,
  getCategoryDescription,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  Authorization,
  FdButton,
  FdSkeleton,
  FdTypography,
  FdCard,
  PERMISSIONS_AFFILIATED,
} from '@fifthdomain/fe-shared';
import { getTaskStatusColor } from '../shared/utils/getTaskStatusColor';
import TaskTable from '../components/TaskTable';
import {
  listTaskOrgs,
  listTasksByOrgId,
  queryTemplatesByStatus,
} from '../graphql/queries';
import CompositionGraphs from '../components/Composition/CompositionGraphs';
import { PROFICIENCY_LEVELS } from '../constants';
import BrowseCatalog from '../components/Task/BrowseCatalog';
import { getDifficultyLevel } from '../shared/utils/difficultyMapping';
import { getReleasedTemplatesChallenges } from '../shared/utils/taskUtils';

const ListTasks = () => {
  const globalSnap = useSnapshot(globalStore);
  const [tabIndex, setTabIndex] = useState(0);
  const isAdminUser =
    globalSnap.userType === 'ADMIN' &&
    Authorization.hasPermission(globalSnap?.permissions, [
      PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
    ]);
  const isFdAdmin = Authorization.isFdAdmin(globalSnap.permissions);
  const isOrgAdminOrFdAdmin = isFdAdmin || globalSnap.userType === 'ADMIN';
  const canUserReviewContent = Authorization.canReviewContent(
    globalSnap?.permissions,
  );

  const {
    data: taskData,
    loading: taskLoading,
    refetch: refetchListTasksByOrgId,
  } = useQueryRecursive(gql(listTasksByOrgId), {
    variables: {
      orgId: globalSnap.orgId,
    },
    skip: !globalSnap.orgId,
  });

  const {
    data: allTasksAvailableToOrg,
    loading: allTasksAvailableLoading,
    refetch: refetchAllTasksAvailable,
  } = useQueryRecursive(gql(listTaskOrgs), {
    variables: {
      orgId: globalSnap.orgId,
      limit: 1000,
    },
    skip: !globalSnap.orgId,
  });

  const {
    data: releasedTemplates,
    loading: templatesLoading,
    refetch: refetchReleasedTemplates,
  } = useQueryRecursive(gql(queryTemplatesByStatus), {
    variables: {
      status: 'RELEASED',
    },
  });

  const refetchTasks = () => {
    refetchListTasksByOrgId();
    refetchAllTasksAvailable();
    refetchReleasedTemplates();
  };
  const loading = taskLoading || allTasksAvailableLoading || templatesLoading;

  const releasedTemplatesChallenges =
    globalSnap.orgPricingTier === 'STARTER'
      ? []
      : getReleasedTemplatesChallenges(
          releasedTemplates?.queryTemplatesByStatus?.items,
        );

  const allTasksMadeAvailable =
    allTasksAvailableToOrg?.listTaskOrgs?.items?.map((taskOrg) => ({
      ...taskOrg.task,
      ownerOrg: taskOrg?.task?.org?.name,
    }));

  const allTasks = _.uniqBy(
    [
      ...(taskData?.listTasksByOrgId?.items.map((task) => ({
        ...task,
        owned: true,
      })) || []),
      ...(allTasksMadeAvailable?.map((task) => ({
        ...task,
        owned: false,
      })) || []),
      ...(releasedTemplatesChallenges?.map((challenge) => ({
        ...challenge,
        owned: challenge?.orgId === globalSnap?.orgId,
      })) || []),
    ],
    'id',
  ).filter((_task) => _task.type !== 'CONTAINER');

  const tasks = allTasks
    ?.map((task) => ({
      id: task.id,
      name: task.name,
      type: task.type,
      category: getCategoryDescription(task.category),
      difficulty: task.difficulty,
      difficultyLabel:
        task?.difficulty > 5
          ? getDifficultyLevel(task?.difficulty)
          : PROFICIENCY_LEVELS[task?.difficulty],
      status: {
        label: task.status !== null ? task.status : 'APPROVED',
        variant:
          task.status !== null
            ? getTaskStatusColor(task.status)
            : getTaskStatusColor('APPROVED'),
      },
      specialtyName: task?.specialty?.name,
      creator: task?.user?.name,
      creatorId: task?.user?.id,
      creatorOrg: task?.user?.org?.name,
      creatorOrgId: task?.user?.org?.id,
      points: task.recommendedPoints,
      estimatedSolveTime: task.estimatedSolveTime,
      summary: task?.summary,
      description: task.description,
      competencies: task.competencies,
      files: task.files,
      hints: task.hints,
      answer: task.answer,
      assignedTags:
        task.tags?.items
          ?.filter((t1) => t1?.Tag?.orgId === globalSnap?.orgId)
          ?.map((t) => ({ ...t.Tag })) || [],
      labId: task.labId,
      lab: task.lab,
      solves: task?.attempts?.items.length,
      solution: task.solution,
      owned: task.owned,
      ownerOrgId: task.orgId,
      ownerOrg: task.ownerOrg || null,
      specialty: task?.specialty?.name,
      assignedOrgs: task?.orgs?.items?.map((org) => org?.org?.name) || [],
      skills: task?.skills?.items?.map((skill) => skill?.skill?.name) || [],
      techniqueTags:
        task?.skills?.items
          ?.map((skill) =>
            skill?.techniqueTags?.items.map((techniqueTag) => ({
              label: techniqueTag.techniqueTag.name,
            })),
          )
          .flat() || [],
      technologyTags:
        task?.technologyTags?.items?.map((tag) => ({
          label: tag?.technologyTag?.name,
        })) || [],
      reviewers: task?.reviewers?.items?.map((r) => ({
        id: r?.assigneeId,
        name: r?.assignee?.name,
        email: r?.assignee?.email,
        assigneeId: r?.assigneeId,
        assignedBy: r?.userId,
        pkId: r?.id,
      })),
      rejectedDate: task?.history?.items?.[0]?.createdAt,
      creatorName:
        task?.userId === globalSnap.userId ? 'You' : task?.user?.name,
      createdDate: task?.createdAt,
    }))
    .filter((_task) => _task.type !== 'CONTAINER');

  const isPublishedTab = tabIndex === 0;
  const isUserAReviewer =
    tasks?.filter(
      (task) =>
        task.status.label === 'IN_REVIEW' &&
        task?.reviewers?.some((r) => r.assigneeId === globalSnap?.userId),
    )?.length > 0;

  const ViewTasks = ({ viewMode }) => {
    return (
      <>
        {/* 
        Tempory hiding as per SD-3254 will be needed in future
        {isAdminUser && isPublishedTab && <BrowseCatalog />} */}
        <FdSkeleton height={785} loading={loading}>
          <TaskTable
            rows={tasks || []}
            viewMode={viewMode}
            refetchTasks={refetchTasks}
            isFdAdmin={isFdAdmin}
          />
        </FdSkeleton>
      </>
    );
  };
  ViewTasks.propTypes = {
    viewMode: PropTypes.string.isRequired,
  };

  return (
    <Box>
      <Box className="flex justify-between">
        <Typography variant="h2">Manage Challenges</Typography>
        {!['STARTER', 'ADVANCED'].includes(globalSnap.orgPricingTier) &&
          Authorization.canManageContent(globalSnap?.permissions) && (
            <FdButton
              onClick={() => singleSpa.navigateToUrl('/tasks/create')}
              size="large"
            >
              Create a Challenge
            </FdButton>
          )}
      </Box>
      <Box className="flex w-full gap-x-2">
        <Box
          className={`w-4/5 ${
            isPublishedTab && isOrgAdminOrFdAdmin ? 'max-w-80' : 'w-full'
          }`}
        >
          <FdTab
            label={[
              {
                label: 'Published',
                index: 0,
                data: <ViewTasks viewMode="PUBLISHED" />,
              },
              {
                label: 'In-Review',
                index: 1,
                data: (
                  <Box className="flex flex-col gap-y-2">
                    {(canUserReviewContent || isUserAReviewer) && (
                      <FdCard variant="outlined">
                        <FdTypography variant="h3">
                          Challenges to be reviewed by you
                        </FdTypography>
                        <ViewTasks viewMode="IN_REVIEW_SELF" />
                      </FdCard>
                    )}
                    <FdCard variant="outlined">
                      <FdTypography variant="h3">
                        Other challenges in-review
                      </FdTypography>
                      <ViewTasks viewMode="IN_REVIEW_OTHERS" />
                    </FdCard>
                  </Box>
                ),
              },
              {
                label: 'Draft',
                index: 2,
                data: <ViewTasks viewMode="DRAFT" />,
              },
              {
                label: 'Disapproved',
                index: 3,
                data: <ViewTasks viewMode="DISAPPROVED" />,
              },
            ]}
            onTabChange={setTabIndex}
          />
        </Box>
        {isPublishedTab && isOrgAdminOrFdAdmin && (
          <Box className="w-1/5" mt={9}>
            <CompositionGraphs
              orgDetails={[]}
              allTasks={allTasks}
              loading={loading}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ListTasks;
