import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { FdChip } from '@fifthdomain/fe-shared';
import { PROFICIENCY } from '../../constants';

const useStyles = makeStyles(() => ({
  cellHeading: {
    lineHeight: '28px',
  },
  condensedChip: {
    fontSize: '10px !important',
    height: '16px !important',
    borderRadius: '4px !important',
    marginBottom: '3px',
    marginRight: '0.5rem',
    padding: '9px 0',
    '& [class*="MuiChip-label"]': {
      padding: '3px 5px',
    },
  },
}));

const ChallengeInfoCell = ({ values, isTransferTaskOwnershipModal }) => {
  const classes = useStyles();
  const { difficultyLabel, name, solves, estimatedSolveTime } = values;

  return (
    <Box>
      <Box className={classes.cellHeading}>{name}</Box>
      <Box display="flex">
        <FdChip
          style={{ backgroundColor: PROFICIENCY[difficultyLabel]?.color }}
          size="small"
          label={difficultyLabel}
          className={classes.condensedChip}
        />
        {!isTransferTaskOwnershipModal && solves === 0 && (
          <FdChip
            color="error"
            size="small"
            label="Never Solved before"
            className={classes.condensedChip}
          />
        )}
        {isTransferTaskOwnershipModal && (
          <FdChip
            color="default"
            size="small"
            label={`Est Time: ${estimatedSolveTime}min`}
            className={classes.condensedChip}
          />
        )}
      </Box>
    </Box>
  );
};

ChallengeInfoCell.propTypes = {
  values: PropTypes.objectOf({
    difficulty: PropTypes.number,
    difficultyLabel: PropTypes.string,
    name: PropTypes.string,
    estimatedSolveTime: PropTypes.string,
  }).isRequired,
  isTransferTaskOwnershipModal: PropTypes.bool,
};

ChallengeInfoCell.defaultProps = {
  isTransferTaskOwnershipModal: false,
};

export default ChallengeInfoCell;
