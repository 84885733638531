import React, { useState, useEffect } from 'react';
import {
  Box,
  Drawer,
  makeStyles,
  IconButton,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import shortid from 'shortid';
import {
  FdTypography,
  FdIcons,
  FdMarkdownRender,
  FdChips,
  FdTooltip,
  FdTab,
} from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';
import setAppMarginRightByIds from '../../shared/utils/layout';
import FileAttachment from '../../shared/utils/FileAttachment';
import { sortByDateField } from '../../shared/utils/dateUtils';
import { getTagColor } from '../../shared/utils/tagUtils';
import TaskDrawerHeader from './TaskDrawerHeader';

const drawerWidth = 400;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  description: {
    wordWrap: 'break-word',
  },
  tagContainer: {
    width: 390,
    display: 'flex',
    flexWrap: 'wrap',
    margin: '1rem 0 1rem 0',
  },
  tag: {
    flex: 1,
    padding: 2,
  },
  drawerCard: {
    height: '100%',
    overflowY: 'scroll',
  },
  condensedChip: {
    fontSize: 10,
    lineHeight: 14,
    borderRadius: '4px',
    height: '14px',
  },
}));

const ViewTaskDrawer = ({
  activeTaskData,
  openDrawer,
  openDrawerCallBack,
  mainPageIds,
}) => {
  const [activeTask, setActiveTask] = useState(activeTaskData);
  const classes = useStyles();
  const { Close, InfoOutlined } = FdIcons;

  const ContentWithLabel = ({ label, content }) => (
    <Box display="flex" mb={2}>
      <Box width="150px">
        <FdTypography variant="subtitle1" data-cy="task-drawer-label">
          {label}
        </FdTypography>
      </Box>
      {content}
    </Box>
  );

  ContentWithLabel.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };

  useEffect(() => {
    setActiveTask(activeTaskData);
  }, [activeTaskData]);

  useEffect(() => {
    setAppMarginRightByIds(mainPageIds, '400px');
    return () => setAppMarginRightByIds(mainPageIds, 0);
  });

  const tagsData =
    activeTask?.assignedTags
      ?.sort(sortByDateField('updatedAt', 'desc'))
      .map((t) => ({
        label: t?.name,
        color: t?.color && getTagColor(t?.color),
      })) || [];

  const showForOwned = activeTask?.owned;

  const Details = () => (
    <Box>
      <TaskDrawerHeader activeTask={activeTask} />
      <FdTab
        variant="fullWidth"
        label={[
          {
            label: 'Challenge Details',
            path: '/tasks',
            index: 0,
            data: () => (
              <Box>
                {activeTask?.summary && (
                  <Box mb={3} className={classes.description}>
                    <Box display="flex" alignItems="center">
                      <FdTypography variant="subtitle1" data-cy="skills-title">
                        Summary
                      </FdTypography>
                      <FdTooltip title="Summary is viewable by admins/managers for quick reference and overview of the challenge for  use-cases such as challenge curation for events, leasing challenges etc.">
                        <IconButton
                          size="small"
                          style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </FdTooltip>
                    </Box>
                    <FdTypography variant="body1" color="secondary">
                      <ShowMoreText
                        more="See more"
                        less="See less"
                        anchorClass="see-more"
                      >
                        <FdMarkdownRender markdown={activeTask?.summary} />
                      </ShowMoreText>
                    </FdTypography>
                  </Box>
                )}
                {activeTask?.skills?.length > 0 && (
                  <Box mb={3}>
                    <Box display="flex" alignItems="center">
                      <FdTypography variant="subtitle1" data-cy="skills-title">
                        Skills
                      </FdTypography>
                      <FdTooltip title="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort.">
                        <IconButton
                          size="small"
                          style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </FdTooltip>
                    </Box>
                    <FdTypography variant="body1" color="secondary">
                      {activeTask?.skills?.map((skill) => (
                        <ul key={shortid.generate()} data-cy="skills">
                          <li>{skill}</li>
                        </ul>
                      ))}
                    </FdTypography>
                  </Box>
                )}
                {activeTask?.techniqueTags.length > 0 && (
                  <Box mb={3}>
                    <Box display="flex" alignItems="center">
                      <FdTypography
                        variant="subtitle1"
                        data-cy="techniques-label"
                      >
                        Techniques
                      </FdTypography>
                      <FdTooltip title="Techniques refer to the specific methods or strategies required to resolve a challenge.">
                        <IconButton
                          size="small"
                          style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </FdTooltip>
                    </Box>
                    <Box className={classes.tagContainer}>
                      <FdChips
                        data={[
                          ...new Set(
                            activeTask?.techniqueTags.map((item) => item.label),
                          ),
                        ]?.map((s) => ({
                          label: s,
                        }))}
                      />
                    </Box>
                  </Box>
                )}
                {activeTask?.technologyTags.length > 0 && (
                  <Box mb={3}>
                    <Box display="flex" alignItems="center">
                      <FdTypography
                        variant="subtitle1"
                        data-cy="technologies-label"
                      >
                        Technologies
                      </FdTypography>
                      <FdTooltip title=" Technologies include the environments (e.g., Windows, Linux, Docker) and tools (e.g., Splunk, Nessus, Python) incorporated within the challenge, crucial for its resolution.">
                        <IconButton
                          size="small"
                          style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </FdTooltip>
                    </Box>
                    <Box className={classes.tagContainer}>
                      <FdChips data={activeTask?.technologyTags} />
                    </Box>
                  </Box>
                )}
                {tagsData?.length > 0 && (
                  <Box mb={2} mt={1}>
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={1}
                      mb={2}
                    >
                      <FdTypography variant="subtitle1" data-cy="tags-label">
                        Tags
                      </FdTypography>
                      <FdTypography variant="body1" data-cy="tags-label">
                        {tagsData?.length}
                      </FdTypography>
                    </Box>
                    <Box className={classes.tagContainer}>
                      <FdChips data={tagsData} />
                    </Box>
                  </Box>
                )}
              </Box>
            ),
          },
          {
            label: 'Challenge Resources',
            path: '/tasks',
            index: 1,
            data: () => (
              <Box>
                {activeTask?.description && (
                  <Box mb={3} className={classes.description}>
                    <Box display="flex" alignItems="center">
                      <FdTypography variant="subtitle1" data-cy="skills-title">
                        Description
                      </FdTypography>
                      <FdTooltip title="Description is viewable by participants for challenge instructions or other related details.">
                        <IconButton
                          size="small"
                          style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </FdTooltip>
                    </Box>
                    <FdTypography variant="body1" color="secondary">
                      <ShowMoreText
                        more="See more"
                        less="See less"
                        anchorClass="see-more"
                      >
                        <FdMarkdownRender markdown={activeTask?.description} />
                      </ShowMoreText>
                    </FdTypography>
                  </Box>
                )}
                {activeTask?.hints?.items?.length !== 0 && (
                  <Box mb={3}>
                    <FdTypography variant="subtitle1" data-cy="hints-title">
                      Hints
                    </FdTypography>
                    <FdTypography variant="body1" color="secondary">
                      {activeTask?.hints?.items?.map((h) => (
                        <ul key={shortid.generate()} data-cy="hints">
                          <li>{h?.text}</li>
                        </ul>
                      ))}
                    </FdTypography>
                  </Box>
                )}
                {activeTask?.files?.items?.length !== 0 && (
                  <Box mb={3}>
                    <FdTypography
                      variant="subtitle1"
                      data-cy="attachments-subtitle"
                    >
                      Attachments
                    </FdTypography>
                    {activeTask?.files?.items?.map((f) => (
                      <FileAttachment id={f.id} name={f.name} url={f.url} />
                    ))}
                  </Box>
                )}

                {showForOwned && activeTask?.solution && (
                  <Box mb={3} className={classes.description}>
                    <FdTypography variant="subtitle1" data-cy="skills-title">
                      Solution
                    </FdTypography>
                    <FdTypography
                      color="secondary"
                      variant="body1"
                      data-cy="solution"
                    >
                      {activeTask?.solution}
                    </FdTypography>
                  </Box>
                )}

                {showForOwned && (
                  <ContentWithLabel
                    label="Flag"
                    content={
                      <FdTypography
                        color="secondary"
                        variant="body1"
                        data-cy="flag"
                      >
                        {activeTask?.answer}
                      </FdTypography>
                    }
                  />
                )}
              </Box>
            ),
          },
        ]}
      />
    </Box>
  );

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openDrawer}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      data-cy="drawer"
    >
      <Card heading="" data-cy="drawer-card" className={classes.drawerCard}>
        <CardHeader
          action={
            <IconButton
              onClick={() => {
                setAppMarginRightByIds(mainPageIds, '0');
                openDrawerCallBack(false);
              }}
            >
              <Close style={{ fontSize: 28 }} />
            </IconButton>
          }
          title={activeTask?.name}
        />
        <CardContent style={{ height: '795px', paddingTop: 0 }}>
          <Details />
        </CardContent>
      </Card>
    </Drawer>
  );
};

ViewTaskDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activeTaskData: PropTypes.object.isRequired,
  openDrawer: PropTypes.bool.isRequired,
  openDrawerCallBack: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mainPageIds: PropTypes.array,
};

ViewTaskDrawer.defaultProps = {
  mainPageIds: [],
};

export default ViewTaskDrawer;
