export const getTaskStatusColor = (status) => {
  switch (status) {
    case 'DRAFT':
      return 'default';
    case 'SUBMITTED':
      return 'warning';
    case 'UNDER_REVIEW':
      return 'action';
    case 'BLOCKED':
      return 'error';
    case 'APPROVED':
      return 'done';
    case 'ARCHIVED':
      return 'default';
    default:
      return 'default';
  }
};
